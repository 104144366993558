import React, { Fragment, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    formatAsNumber,
    getRequestContactEndpoint,
    numberFormatNoSeparators,
    RequestContactBO,
    SendRequestContact,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { carOrVehicleText, textWithComponents } from 'utils';
import { linkToDashboardPage, linkToFaqPage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { ValidatedSelect, ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';
import { ValidatedTextarea } from 'components/validated-textarea/ValidatedTextarea';
import { ValidatedInput } from 'components/validated-input/ValidatedInput';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { selectRequestContact } from './RequestContactSelector';
import { fetchRequestContact } from './RequestContactSlice';
import { validationSchema } from './RequestContactValidation';
import {
    considerEndingProductCategory,
    reasonValueFromName,
    reasonCategory,
    showAnyFields,
    showConsiderEndingProductFields,
    showConsiderEndingProductHireFields,
    showConsiderEndingProductLeaseFields,
    showConsiderEndingProductPurchaseFields,
    showFinancialDifficultyFields,
    showOtherFields,
} from './helpers';
import {
    ConsiderEndingProductHireFormValues,
    ConsiderEndingProductLeaseFormValues,
    ConsiderEndingProductPurchaseFormValues,
    ConsiderEndingProducts,
    ContinueOptions,
    Durations,
    Reasons,
    ReceivingIncomeFormValues,
    RequestContactFormValues,
} from './types';

export type RequestContactProps = {
    initialReason: string | undefined;
};

const RequestContactUi: React.FC<RequestContactProps & { requestContact: RequestContactBO | undefined }> = ({
    initialReason,
    requestContact,
}) => {
    const { t } = useTranslation('request-contact');
    const history = useHistory();
    const pound = '£';
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const { onAction: onContactSubmitSuccessAction } = useAnalyticsActionTracker('onContactSubmitSuccess');
    const { onAction: onContactSubmitFailedAction } = useAnalyticsActionTracker('onContactSubmitFailed');
    useAnalyticsPageViewTracker('contact', !!requestContact);

    if (requestContact === undefined) {
        return null;
    }

    const {
        encryptedContractIds,
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    } = requestContact;

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const carOrVehicle = carOrVehicleText();

    const phoneNumberLabel = (key: string, value: string): string => {
        return `${t(`phoneNumbers.${key}`)} - ${value}`;
    };

    const reasonLabel = (key: string): string => {
        return t(`reasons.${key}`);
    };

    const receivingIncomeLabel = (key: string): string => {
        return t(`receivingIncome.${key}.label`);
    };

    const durationLabel = (key: string): string => {
        return key ? t(`durations.${key}`) : '';
    };

    const continueOptionLabel = (key: string): string => {
        return t(`continueOptions.${key}`);
    };

    const considerEndingProductLabel = (key: string): string => {
        return t(`considerEndingProducts.${key}`);
    };

    const considerEndingProductOptionsLabel = (key: string): string => {
        return t(`considerEndingProductOptions.${key}.label`);
    };

    const phoneNumberSelectItems = (): ValidatedSelectItem[] => {
        const phoneNumbers = {
            mobileNumber,
            homePhoneNumber,
            workPhoneNumber,
        };
        return Object.entries(phoneNumbers)
            .filter(([, phoneNumberValue]) => !!phoneNumberValue)
            .map(
                ([phoneNumberName, phoneNumberValue]): ValidatedSelectItem => {
                    return {
                        label: phoneNumberLabel(phoneNumberName, phoneNumberValue ?? ''),
                        value: phoneNumberValue ?? '',
                    };
                },
            );
    };

    const reasonSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(Reasons).map(
            ([, reasonValue]): ValidatedSelectItem => {
                return {
                    label: reasonLabel(reasonValue),
                    value: reasonValue,
                };
            },
        );
    };

    const durationSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(Durations).map(
            ([, durationValue]): ValidatedSelectItem => {
                return {
                    label: durationLabel(durationValue),
                    value: durationValue,
                };
            },
        );
    };

    const continueOptionSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(ContinueOptions).map(
            ([, continueOptionValue]): ValidatedSelectItem => {
                return {
                    label: continueOptionLabel(continueOptionValue),
                    value: continueOptionValue,
                };
            },
        );
    };

    const considerEndingProductSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(ConsiderEndingProducts).map(
            ([, considerEndingProductValue]): ValidatedSelectItem => {
                return {
                    label: considerEndingProductLabel(considerEndingProductValue),
                    value: considerEndingProductValue,
                };
            },
        );
    };

    const totalFormatted = (total: number): string => {
        return Number.isNaN(total) ? t('money.validation.total') : formatAsNumber(total, numberFormatNoSeparators);
    };

    const initialValues: RequestContactFormValues = {
        vulnerableCustomer: false,
        phoneNumber: '',
        reason: reasonValueFromName(initialReason),
        message: '',
        causeFinancialDifficulty: '',
        receivingIncome: {
            none: false,
            wage: false,
            benefits: false,
            govtContrib: false,
        },
        behindWithOther: false,
        behindWithOtherDetail: '',
        knowWhenReturnNormal: false,
        knowWhenReturnNormalDuration: '',
        notMaintainMonthlyPayments: false,
        notMaintainMonthlyPaymentsDetail: false,
        notMaintainMonthlyPaymentsDuration: '',
        continueOption: '',
        additionalSupport: false,
        additionalSupportDetail: '',
        consentInformationProvided: false,
        incomeSalaryWages: '0',
        incomeBenefitsOther: '0',
        incomeTotal: '0',
        expenditureVolkswagenFinance: '0',
        expenditureRentMortgage: '0',
        expenditureWaterGasElectric: '0',
        expenditureCouncilTax: '0',
        expenditureChildCareMaintenance: '0',
        expenditureLandlineInternetTv: '0',
        expenditureMobiles: '0',
        expenditureCarInsuranceTax: '0',
        expenditureFuelTravelCosts: '0',
        expenditureHousekeeping: '0',
        expenditureOther: '0',
        expenditureOtherCreditors: '0',
        expenditureTotal: '0',
        disposableIncomeTotal: '0',
        considerEndingProduct: '',
        considerEndingProductPurchase: {
            sell: false,
            partExchange: false,
            voluntaryTermination: false,
        },
        considerEndingProductHire: {
            earlyTermination: false,
        },
        considerEndingProductLease: {
            leaseSettlement: false,
        },
    };

    const resetBehindWithOtherDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('behindWithOtherDetail', '');
        setFieldTouched('behindWithOtherDetail', false);
    };

    const resetKnowWhenReturnNormalDuration = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('knowWhenReturnNormalDuration', '');
        setFieldTouched('knowWhenReturnNormalDuration', false);
    };

    const resetNotMaintainMonthlyPaymentsDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('notMaintainMonthlyPaymentsDetail', false);
        setFieldTouched('notMaintainMonthlyPaymentsDetail', false);
    };

    const resetNotMaintainMonthlyPaymentsDuration = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('notMaintainMonthlyPaymentsDuration', '');
        setFieldTouched('notMaintainMonthlyPaymentsDuration', false);
    };

    const resetAdditionalSupportDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('additionalSupportDetail', '');
        setFieldTouched('additionalSupportDetail', false);
    };

    const resetConsiderEndingProduct = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<RequestContactFormValues>): void => {
        setFieldValue('considerEndingProduct', '');
        setFieldTouched('considerEndingProduct', false);
    };

    const resetConsiderEndingProductOptions = (
        earlyTermination: boolean,
        leaseSettlement: boolean,
        { setFieldValue, setFieldTouched }: FormikProps<RequestContactFormValues>,
    ): void => {
        setFieldValue('considerEndingProductPurchase.sell', false);
        setFieldTouched('considerEndingProductPurchase.sell', false);
        setFieldValue('considerEndingProductPurchase.partExchange', false);
        setFieldTouched('considerEndingProductPurchase.partExchange', false);
        setFieldValue('considerEndingProductPurchase.voluntaryTermination', false);
        setFieldTouched('considerEndingProductPurchase.voluntaryTermination', false);
        setFieldTouched('considerEndingProductPurchase', false);

        setFieldValue('considerEndingProductHire.earlyTermination', earlyTermination);
        setFieldTouched('considerEndingProductHire.earlyTermination', false);
        setFieldTouched('considerEndingProductHire', false);

        setFieldValue('considerEndingProductLease.leaseSettlement', leaseSettlement);
        setFieldTouched('considerEndingProductLease.leaseSettlement', false);
        setFieldTouched('considerEndingProductLease', false);
    };

    const onReasonChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLSelectElement>,
        { values, resetForm }: FormikProps<RequestContactFormValues>,
    ): void => {
        const oldValue = values.reason;
        if (reasonCategory(oldValue as Reasons) !== reasonCategory(newValue as Reasons)) {
            resetForm({
                values: {
                    ...initialValues,
                    vulnerableCustomer: values.vulnerableCustomer,
                    phoneNumber: values.phoneNumber,
                    reason: newValue,
                },
            });
        }
    };

    const onBehindWithOtherChange = (
        { target: { checked: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!newValue) {
            resetBehindWithOtherDetail(formik);
        }
    };

    const onKnowWhenReturnNormalChange = (
        { target: { checked: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!newValue) {
            resetKnowWhenReturnNormalDuration(formik);
        }
    };

    const onNotMaintainMonthlyPaymentsChange = (
        { target: { checked: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!newValue) {
            resetNotMaintainMonthlyPaymentsDetail(formik);
            resetNotMaintainMonthlyPaymentsDuration(formik);
        }
    };

    const onNotMaintainMonthlyPaymentsDetailChange = (
        { target: { checked: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!newValue) {
            resetNotMaintainMonthlyPaymentsDuration(formik);
        }
    };

    const onContinueOptionsChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLSelectElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!showConsiderEndingProductFields(newValue as ContinueOptions)) {
            resetConsiderEndingProduct(formik);
            resetConsiderEndingProductOptions(false, false, formik);
        }
    };

    const onAdditionalSupportChange = (
        { target: { checked: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        if (!newValue) {
            resetAdditionalSupportDetail(formik);
        }
    };

    const onMoneyChange = (
        { target: { value: newValue, name: targetName } }: React.ChangeEvent<HTMLInputElement>,
        { values, setFieldValue }: FormikProps<RequestContactFormValues>,
    ): void => {
        const clonedValues: RequestContactFormValues = {
            ...values,
        };
        clonedValues[targetName] = newValue.replace(/[^0-9]/g, '');

        const incomeSalaryWages = clonedValues.incomeSalaryWages;
        const incomeBenefitsOther = clonedValues.incomeBenefitsOther;

        const expenditureVolkswagenFinance = clonedValues.expenditureVolkswagenFinance;
        const expenditureRentMortgage = clonedValues.expenditureRentMortgage;
        const expenditureWaterGasElectric = clonedValues.expenditureWaterGasElectric;
        const expenditureCouncilTax = clonedValues.expenditureCouncilTax;
        const expenditureChildCareMaintenance = clonedValues.expenditureChildCareMaintenance;
        const expenditureLandlineInternetTv = clonedValues.expenditureLandlineInternetTv;
        const expenditureMobiles = clonedValues.expenditureMobiles;
        const expenditureCarInsuranceTax = clonedValues.expenditureCarInsuranceTax;
        const expenditureFuelTravelCosts = clonedValues.expenditureFuelTravelCosts;
        const expenditureHousekeeping = clonedValues.expenditureHousekeeping;
        const expenditureOther = clonedValues.expenditureOther;
        const expenditureOtherCreditors = clonedValues.expenditureOtherCreditors;

        const incomeTotal = Number(incomeSalaryWages) + Number(incomeBenefitsOther);
        const expenditureTotal =
            Number(expenditureVolkswagenFinance) +
            Number(expenditureRentMortgage) +
            Number(expenditureWaterGasElectric) +
            Number(expenditureCouncilTax) +
            Number(expenditureChildCareMaintenance) +
            Number(expenditureLandlineInternetTv) +
            Number(expenditureMobiles) +
            Number(expenditureCarInsuranceTax) +
            Number(expenditureFuelTravelCosts) +
            Number(expenditureHousekeeping) +
            Number(expenditureOther) +
            Number(expenditureOtherCreditors);
        const disposableIncomeTotal = Number(incomeTotal) - Number(expenditureTotal);

        setFieldValue('incomeTotal', totalFormatted(incomeTotal));
        setFieldValue('expenditureTotal', totalFormatted(expenditureTotal));
        setFieldValue('disposableIncomeTotal', totalFormatted(disposableIncomeTotal));
    };

    const onConsiderEndingProductChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLSelectElement>,
        formik: FormikProps<RequestContactFormValues>,
    ): void => {
        const oldValue = formik.values.considerEndingProduct;
        if (
            considerEndingProductCategory(newValue as ConsiderEndingProducts) !==
            considerEndingProductCategory(oldValue as ConsiderEndingProducts)
        ) {
            const earlyTermination = showConsiderEndingProductHireFields(newValue as ConsiderEndingProducts);
            const leaseSettlement = showConsiderEndingProductLeaseFields(newValue as ConsiderEndingProducts);

            resetConsiderEndingProductOptions(earlyTermination, leaseSettlement, formik);
        }
    };

    const receivingIncomeLabels = ({ none, wage, benefits, govtContrib }: ReceivingIncomeFormValues): string => {
        const array = [];
        if (none) {
            array.push(receivingIncomeLabel('none'));
        }
        if (wage) {
            array.push(receivingIncomeLabel('wage'));
        }
        if (benefits) {
            array.push(receivingIncomeLabel('benefits'));
        }
        if (govtContrib) {
            array.push(receivingIncomeLabel('govtContrib'));
        }
        return array.join(', ');
    };

    const considerEndingProductOptionsLabels = (
        purchase: ConsiderEndingProductPurchaseFormValues,
        hire: ConsiderEndingProductHireFormValues,
        lease: ConsiderEndingProductLeaseFormValues,
    ): string => {
        const array = [];
        if (purchase.sell) {
            array.push(considerEndingProductOptionsLabel('purchase.sell'));
        }
        if (purchase.partExchange) {
            array.push(considerEndingProductOptionsLabel('purchase.partExchange'));
        }
        if (purchase.voluntaryTermination) {
            array.push(considerEndingProductOptionsLabel('purchase.voluntaryTermination'));
        }

        if (hire.earlyTermination) {
            array.push(considerEndingProductOptionsLabel('hire.earlyTermination'));
        }

        if (lease.leaseSettlement) {
            array.push(considerEndingProductOptionsLabel('lease.leaseSettlement'));
        }
        return array.join(', ');
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
    };

    const buildSendRequestContact = (values: RequestContactFormValues): SendRequestContact => {
        const commonFields: SendRequestContact = {
            encryptedContractIds,
            vulnerableCustomer: values.vulnerableCustomer,
            firstName,
            lastName,
            emailAddress: emailAddress ?? '',
            phoneNumber: values.phoneNumber,
            reason: reasonLabel(values.reason),
            showOtherFields: false,
            message: undefined,
            showFinancialDifficultyFields: false,
            causeFinancialDifficulty: undefined,
            receivingIncome: undefined,
            behindWithOther: undefined,
            behindWithOtherDetail: undefined,
            knowWhenReturnNormal: undefined,
            knowWhenReturnNormalDuration: undefined,
            notMaintainMonthlyPayments: undefined,
            notMaintainMonthlyPaymentsDetail: undefined,
            notMaintainMonthlyPaymentsDuration: undefined,
            continueOption: undefined,
            additionalSupport: undefined,
            additionalSupportDetail: undefined,
            consentInformationProvided: undefined,
            incomeSalaryWages: undefined,
            incomeBenefitsOther: undefined,
            incomeTotal: undefined,
            expenditureVolkswagenFinance: undefined,
            expenditureRentMortgage: undefined,
            expenditureWaterGasElectric: undefined,
            expenditureCouncilTax: undefined,
            expenditureChildCareMaintenance: undefined,
            expenditureLandlineInternetTv: undefined,
            expenditureMobiles: undefined,
            expenditureCarInsuranceTax: undefined,
            expenditureFuelTravelCosts: undefined,
            expenditureHousekeeping: undefined,
            expenditureOther: undefined,
            expenditureOtherCreditors: undefined,
            expenditureTotal: undefined,
            disposableIncomeTotal: undefined,
            considerEndingProduct: undefined,
            considerEndingProductOptions: undefined,
        };

        let sendRequestContact: SendRequestContact;
        if (showOtherFields(values.reason as Reasons)) {
            const otherFields: Partial<SendRequestContact> = {
                showOtherFields: true,
                message: values.message,
            };

            sendRequestContact = {
                ...commonFields,
                ...otherFields,
            };
        } else if (showFinancialDifficultyFields(values.reason as Reasons)) {
            const financialDifficultyFields: Partial<SendRequestContact> = {
                showFinancialDifficultyFields: true,
                causeFinancialDifficulty: values.causeFinancialDifficulty,
                receivingIncome: receivingIncomeLabels(values.receivingIncome),
                behindWithOther: values.behindWithOther,
                behindWithOtherDetail: values.behindWithOtherDetail,
                knowWhenReturnNormal: values.knowWhenReturnNormal,
                knowWhenReturnNormalDuration: durationLabel(values.knowWhenReturnNormalDuration),
                notMaintainMonthlyPayments: values.notMaintainMonthlyPayments,
                notMaintainMonthlyPaymentsDetail: values.notMaintainMonthlyPaymentsDetail,
                notMaintainMonthlyPaymentsDuration: durationLabel(values.notMaintainMonthlyPaymentsDuration),
                continueOption: continueOptionLabel(values.continueOption),
                additionalSupport: values.additionalSupport,
                additionalSupportDetail: values.additionalSupportDetail,
                consentInformationProvided: values.consentInformationProvided,
                incomeSalaryWages: Number(values.incomeSalaryWages),
                incomeBenefitsOther: Number(values.incomeBenefitsOther),
                incomeTotal: Number(values.incomeTotal),
                expenditureVolkswagenFinance: Number(values.expenditureVolkswagenFinance),
                expenditureRentMortgage: Number(values.expenditureRentMortgage),
                expenditureWaterGasElectric: Number(values.expenditureWaterGasElectric),
                expenditureCouncilTax: Number(values.expenditureCouncilTax),
                expenditureChildCareMaintenance: Number(values.expenditureChildCareMaintenance),
                expenditureLandlineInternetTv: Number(values.expenditureLandlineInternetTv),
                expenditureMobiles: Number(values.expenditureMobiles),
                expenditureCarInsuranceTax: Number(values.expenditureCarInsuranceTax),
                expenditureFuelTravelCosts: Number(values.expenditureFuelTravelCosts),
                expenditureHousekeeping: Number(values.expenditureHousekeeping),
                expenditureOther: Number(values.expenditureOther),
                expenditureOtherCreditors: Number(values.expenditureOtherCreditors),
                expenditureTotal: Number(values.expenditureTotal),
                disposableIncomeTotal: Number(values.disposableIncomeTotal),
                considerEndingProduct: considerEndingProductLabel(values.considerEndingProduct),
                considerEndingProductOptions: considerEndingProductOptionsLabels(
                    values.considerEndingProductPurchase,
                    values.considerEndingProductHire,
                    values.considerEndingProductLease,
                ),
            };

            sendRequestContact = {
                ...commonFields,
                ...financialDifficultyFields,
            };
        } else {
            sendRequestContact = commonFields;
            alert('Unexpected state for buildSendRequestContact()!');
        }

        return sendRequestContact;
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ContactDetailsNotification
                className={'u-mb'}
                mode={contactDetailsNotificationMode}
                emailAddress={emailAddress}
            />
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                testId={'adviceNotification'}
            >
                {textWithComponents(t, 'notifications.advice.text', { linkToFaqPage })}
            </Notification>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values: RequestContactFormValues): Promise<void> => {
                    const sendRequestContact = buildSendRequestContact(values);
                    setIsSubmitting(true);
                    return CpDataApi.post<void>(getRequestContactEndpoint(), sendRequestContact)
                        .then(() => {
                            setShowSuccessModal(true);
                            setIsSubmitting(false);
                            onContactSubmitSuccessAction(sendRequestContact.reason);
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setIsSubmitting(false);
                            onContactSubmitFailedAction(sendRequestContact.reason);
                        });
                }}
            >
                {(formik: FormikProps<RequestContactFormValues>): React.ReactNode => (
                    <Form className={'uk-request-contact-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={t('vulnerableCustomer.label')}
                                    name={'vulnerableCustomer'}
                                    testId={'vulnerableCustomer'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-phone-number'}
                                    label={t('phoneNumber.label')}
                                    placeholder={t('phoneNumber.placeholder')}
                                    selectItems={phoneNumberSelectItems()}
                                    name={'phoneNumber'}
                                    testId={'phoneNumber'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-reason'}
                                    label={t('reason.label')}
                                    placeholder={t('reason.placeholder')}
                                    selectItems={reasonSelectItems()}
                                    name={'reason'}
                                    testId={'reason'}
                                    isMandatory
                                    handleChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                                        onReasonChange(e, formik);
                                    }}
                                />
                                {showAnyFields(formik.values.reason as Reasons) && (
                                    <Paragraph
                                        className={'u-mt-small u-mb-none u-text-italic'}
                                        testId={'reasonDescriptionParagraph'}
                                    >
                                        {textWithComponents(
                                            t,
                                            `paragraphs.reasonDescriptions.${formik.values.reason}`,
                                            { linkToDashboardPage },
                                            { carOrVehicle },
                                        )}
                                    </Paragraph>
                                )}
                            </Fieldset.Row>
                            {showOtherFields(formik.values.reason as Reasons) && (
                                <Fieldset.Row>
                                    <ValidatedTextarea
                                        label={t('message.label')}
                                        helpText={t('message.helpText')}
                                        name={'message'}
                                        testId={'message'}
                                        rows={5}
                                        isMandatory
                                    />
                                </Fieldset.Row>
                            )}
                            {showFinancialDifficultyFields(formik.values.reason as Reasons) && (
                                <Fragment>
                                    <Fieldset.Row>
                                        <ValidatedTextarea
                                            label={t('causeFinancialDifficulty.label')}
                                            name={'causeFinancialDifficulty'}
                                            testId={'causeFinancialDifficulty'}
                                            rows={5}
                                            isMandatory
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckboxGroup
                                            className={'u-indent'}
                                            label={t('receivingIncome.label')}
                                            name={'receivingIncome'}
                                            testId={'receivingIncome'}
                                            isMandatory={true}
                                        >
                                            {Object.keys(formik.values.receivingIncome).map((key) => (
                                                <ValidatedCheckboxGroup.Checkbox
                                                    key={key}
                                                    className={'u-mb-xsmall'}
                                                    label={receivingIncomeLabel(key)}
                                                    groupName={'receivingIncome'}
                                                    name={`receivingIncome.${key}`}
                                                    testId={`receivingIncome.${key}`}
                                                />
                                            ))}
                                        </ValidatedCheckboxGroup>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            label={t('behindWithOther.label')}
                                            name={'behindWithOther'}
                                            testId={'behindWithOther'}
                                            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                onBehindWithOtherChange(e, formik);
                                            }}
                                        />
                                        <div className={'u-indent u-mt-xsmall'}>
                                            <ValidatedTextarea
                                                label={t('behindWithOtherDetail.label')}
                                                name={'behindWithOtherDetail'}
                                                testId={'behindWithOtherDetail'}
                                                rows={5}
                                                disabled={!formik.values.behindWithOther}
                                                isMandatory={formik.values.behindWithOther}
                                            />
                                        </div>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            label={t('knowWhenReturnNormal.label')}
                                            name={'knowWhenReturnNormal'}
                                            testId={'knowWhenReturnNormal'}
                                            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                onKnowWhenReturnNormalChange(e, formik);
                                            }}
                                        />
                                        <div className={'u-indent u-mt-xsmall'}>
                                            <ValidatedSelect
                                                className={'select-duration'}
                                                label={t('knowWhenReturnNormalDuration.label')}
                                                placeholder={t('durations.placeholder')}
                                                selectItems={durationSelectItems()}
                                                name={'knowWhenReturnNormalDuration'}
                                                testId={'knowWhenReturnNormalDuration'}
                                                disabled={!formik.values.knowWhenReturnNormal}
                                                isMandatory={formik.values.knowWhenReturnNormal}
                                            />
                                        </div>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            label={t('notMaintainMonthlyPayments.label')}
                                            name={'notMaintainMonthlyPayments'}
                                            testId={'notMaintainMonthlyPayments'}
                                            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                onNotMaintainMonthlyPaymentsChange(e, formik);
                                            }}
                                        />
                                        <div className={'u-indent u-mt-xsmall'}>
                                            <ValidatedCheckbox
                                                label={t('notMaintainMonthlyPaymentsDetail.label')}
                                                name={'notMaintainMonthlyPaymentsDetail'}
                                                testId={'notMaintainMonthlyPaymentsDetail'}
                                                disabled={!formik.values.notMaintainMonthlyPayments}
                                                handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    onNotMaintainMonthlyPaymentsDetailChange(e, formik);
                                                }}
                                            />
                                            <div className={'u-indent u-mt-xsmall'}>
                                                <ValidatedSelect
                                                    className={'select-duration'}
                                                    label={t('notMaintainMonthlyPaymentsDuration.label')}
                                                    placeholder={t('durations.placeholder')}
                                                    selectItems={durationSelectItems()}
                                                    name={'notMaintainMonthlyPaymentsDuration'}
                                                    testId={'notMaintainMonthlyPaymentsDuration'}
                                                    disabled={!formik.values.notMaintainMonthlyPaymentsDetail}
                                                    isMandatory={formik.values.notMaintainMonthlyPaymentsDetail}
                                                />
                                            </div>
                                        </div>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedSelect
                                            className={'select-continue-option'}
                                            label={t('continueOption.label')}
                                            placeholder={t('continueOption.placeholder')}
                                            selectItems={continueOptionSelectItems()}
                                            name={'continueOption'}
                                            testId={'continueOption'}
                                            isMandatory
                                            handleChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                                                onContinueOptionsChange(e, formik);
                                            }}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            label={t('additionalSupport.label')}
                                            name={'additionalSupport'}
                                            testId={'additionalSupport'}
                                            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                onAdditionalSupportChange(e, formik);
                                            }}
                                        />
                                        <div className={'u-indent u-mt-xsmall'}>
                                            <ValidatedTextarea
                                                label={t('additionalSupportDetail.label')}
                                                name={'additionalSupportDetail'}
                                                testId={'additionalSupportDetail'}
                                                rows={5}
                                                disabled={!formik.values.additionalSupport}
                                                isMandatory={formik.values.additionalSupport}
                                            />
                                        </div>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            label={t('consentInformationProvided.label')}
                                            name={'consentInformationProvided'}
                                            testId={'consentInformationProvided'}
                                            isMandatory
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Paragraph>{t('incomeAndExpenditure.heading')}</Paragraph>
                                        <div className={'u-indent u-mb'}>
                                            <Paragraph>{t('incomeAndExpenditure.income.subHeading')}</Paragraph>
                                            <div className={'u-indent'}>
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.income.salaryWages.label')}
                                                    name={'incomeSalaryWages'}
                                                    testId={'incomeSalaryWages'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.income.benefitsOther.label')}
                                                    name={'incomeBenefitsOther'}
                                                    testId={'incomeBenefitsOther'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money'}
                                                    label={t('incomeAndExpenditure.income.total.label')}
                                                    name={'incomeTotal'}
                                                    testId={'incomeTotal'}
                                                    addonText={pound}
                                                    reversed
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className={'u-indent u-mb'}>
                                            <Paragraph>{t('incomeAndExpenditure.expenditure.subHeading')}</Paragraph>
                                            <div className={'u-indent'}>
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t(
                                                        'incomeAndExpenditure.expenditure.volkswagenFinance.label',
                                                    )}
                                                    name={'expenditureVolkswagenFinance'}
                                                    testId={'expenditureVolkswagenFinance'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.rentMortgage.label')}
                                                    name={'expenditureRentMortgage'}
                                                    testId={'expenditureRentMortgage'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.waterGasElectric.label')}
                                                    name={'expenditureWaterGasElectric'}
                                                    testId={'expenditureWaterGasElectric'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.councilTax.label')}
                                                    name={'expenditureCouncilTax'}
                                                    testId={'expenditureCouncilTax'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.childCareMaint.label')}
                                                    name={'expenditureChildCareMaintenance'}
                                                    testId={'expenditureChildCareMaintenance'}
                                                    addonText={pound}
                                                    isMandatory
                                                    reversed
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t(
                                                        'incomeAndExpenditure.expenditure.landlineInternetTV.label',
                                                    )}
                                                    name={'expenditureLandlineInternetTv'}
                                                    testId={'expenditureLandlineInternetTv'}
                                                    addonText={pound}
                                                    isMandatory
                                                    reversed
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.mobiles.label')}
                                                    name={'expenditureMobiles'}
                                                    testId={'expenditureMobiles'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.carInsuranceTax.label')}
                                                    name={'expenditureCarInsuranceTax'}
                                                    testId={'expenditureCarInsuranceTax'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.fuelTravelCosts.label')}
                                                    name={'expenditureFuelTravelCosts'}
                                                    testId={'expenditureFuelTravelCosts'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.housekeeping.label')}
                                                    name={'expenditureHousekeeping'}
                                                    testId={'expenditureHousekeeping'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money u-mb-xsmall'}
                                                    label={t('incomeAndExpenditure.expenditure.other.label')}
                                                    name={'expenditureOther'}
                                                    testId={'expenditureOther'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    label={t('incomeAndExpenditure.expenditure.otherCreditors.label')}
                                                    className={'input-money u-mb-xsmall'}
                                                    name={'expenditureOtherCreditors'}
                                                    testId={'expenditureOtherCreditors'}
                                                    addonText={pound}
                                                    reversed
                                                    isMandatory
                                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                        onMoneyChange(e, formik);
                                                    }}
                                                />
                                                <ValidatedInput
                                                    className={'input-money'}
                                                    label={t('incomeAndExpenditure.expenditure.total.label')}
                                                    name={'expenditureTotal'}
                                                    testId={'expenditureTotal'}
                                                    addonText={pound}
                                                    reversed
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className={'u-indent'}>
                                            <ValidatedInput
                                                className={'input-money'}
                                                label={t('incomeAndExpenditure.disposableIncome.total.label')}
                                                name={'disposableIncomeTotal'}
                                                testId={'disposableIncomeTotal'}
                                                addonText={pound}
                                                reversed
                                                disabled
                                            />
                                        </div>
                                    </Fieldset.Row>
                                    {showConsiderEndingProductFields(
                                        formik.values.continueOption as ContinueOptions,
                                    ) && (
                                        <Fieldset.Row>
                                            <ValidatedSelect
                                                className={'select-consider-ending-product u-mb'}
                                                label={t('considerEndingProduct.label')}
                                                placeholder={t('considerEndingProduct.placeholder')}
                                                selectItems={considerEndingProductSelectItems()}
                                                name={'considerEndingProduct'}
                                                testId={'considerEndingProduct'}
                                                isMandatory
                                                handleChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                                                    onConsiderEndingProductChange(e, formik);
                                                }}
                                            />
                                            <div className={'u-indent'}>
                                                {showConsiderEndingProductPurchaseFields(
                                                    formik.values.considerEndingProduct as ConsiderEndingProducts,
                                                ) && (
                                                    <Fragment>
                                                        <ValidatedCheckboxGroup
                                                            className={'u-mb'}
                                                            label={t('considerEndingProduct.label')}
                                                            name={'considerEndingProductPurchase'}
                                                            testId={'considerEndingProductPurchase'}
                                                            isMandatory
                                                        >
                                                            <ValidatedCheckboxGroup.Checkbox
                                                                className={'u-mb'}
                                                                label={t(
                                                                    `considerEndingProductOptions.purchase.sell.label`,
                                                                )}
                                                                helpText={t(
                                                                    'considerEndingProductOptions.purchase.sell.helpText',
                                                                )}
                                                                groupName={'considerEndingProductPurchase'}
                                                                name={'considerEndingProductPurchase.sell'}
                                                                testId={'considerEndingProductPurchase.sell'}
                                                            />
                                                            <ValidatedCheckboxGroup.Checkbox
                                                                className={'u-mb'}
                                                                label={t(
                                                                    `considerEndingProductOptions.purchase.partExchange.label`,
                                                                )}
                                                                helpText={t(
                                                                    'considerEndingProductOptions.purchase.partExchange.helpText',
                                                                )}
                                                                groupName={'considerEndingProductPurchase'}
                                                                name={'considerEndingProductPurchase.partExchange'}
                                                                testId={'considerEndingProductPurchase.partExchange'}
                                                            />
                                                            <ValidatedCheckboxGroup.Checkbox
                                                                label={t(
                                                                    `considerEndingProductOptions.purchase.voluntaryTermination.label`,
                                                                )}
                                                                helpText={t(
                                                                    'considerEndingProductOptions.purchase.voluntaryTermination.helpText',
                                                                )}
                                                                groupName={'considerEndingProductPurchase'}
                                                                name={
                                                                    'considerEndingProductPurchase.voluntaryTermination'
                                                                }
                                                                testId={
                                                                    'considerEndingProductPurchase.voluntaryTermination'
                                                                }
                                                            />
                                                        </ValidatedCheckboxGroup>
                                                        <Paragraph>
                                                            {t('considerEndingProductOptions.purchase.common')}
                                                        </Paragraph>
                                                    </Fragment>
                                                )}
                                                {showConsiderEndingProductHireFields(
                                                    formik.values.considerEndingProduct as ConsiderEndingProducts,
                                                ) && (
                                                    <Fragment>
                                                        <ValidatedCheckboxGroup
                                                            className={'u-mb'}
                                                            label={t('considerEndingProductOptions.label')}
                                                            name={'considerEndingProductHire'}
                                                            testId={'considerEndingProductHire'}
                                                            isMandatory
                                                        >
                                                            <ValidatedCheckboxGroup.Checkbox
                                                                label={t(
                                                                    `considerEndingProductOptions.hire.earlyTermination.label`,
                                                                )}
                                                                helpText={t(
                                                                    'considerEndingProductOptions.hire.earlyTermination.helpText',
                                                                )}
                                                                groupName={'considerEndingProductHire'}
                                                                name={'considerEndingProductHire.earlyTermination'}
                                                                testId={'considerEndingProductHire.earlyTermination'}
                                                                disabled
                                                            />
                                                        </ValidatedCheckboxGroup>
                                                        <Paragraph>
                                                            {t('considerEndingProductOptions.hire.common')}
                                                        </Paragraph>
                                                    </Fragment>
                                                )}
                                                {showConsiderEndingProductLeaseFields(
                                                    formik.values.considerEndingProduct as ConsiderEndingProducts,
                                                ) && (
                                                    <Fragment>
                                                        <ValidatedCheckboxGroup
                                                            className={'u-mb'}
                                                            label={t('considerEndingProductOptions.label')}
                                                            name={'considerEndingProductHire'}
                                                            testId={'considerEndingProductHire'}
                                                            isMandatory
                                                        >
                                                            <ValidatedCheckboxGroup.Checkbox
                                                                label={t(
                                                                    `considerEndingProductOptions.lease.leaseSettlement.label`,
                                                                )}
                                                                helpText={t(
                                                                    'considerEndingProductOptions.lease.leaseSettlement.helpText',
                                                                )}
                                                                groupName={'considerEndingProductLease'}
                                                                name={'considerEndingProductLease.leaseSettlement'}
                                                                testId={'considerEndingProductLease.leaseSettlement'}
                                                                disabled
                                                            />
                                                        </ValidatedCheckboxGroup>
                                                        <Paragraph>
                                                            {t('considerEndingProductOptions.lease.common')}
                                                        </Paragraph>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </Fieldset.Row>
                                    )}
                                </Fragment>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        secondary
                                        testId={'cancelButton'}
                                    >
                                        {t('translation:editableSectionNav.cancel')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                        testId={'requestButton'}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                className={'modal-success'}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{t('successModal.contents')}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                className={'modal-error'}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </Suspense>
    );
};

const RequestContactWithHandlers = withLoadingAndNoConnectionHandler(RequestContactUi);

export const RequestContact: React.FC<RequestContactProps> = ({ initialReason }) => {
    const { data: requestContact, isLoading, loadingError } = useGetSimpleApiData(
        fetchRequestContact,
        getSimpleDataSelector(selectRequestContact),
    );

    return (
        <RequestContactWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            initialReason={initialReason}
            requestContact={requestContact}
        />
    );
};
