import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonContainer,
    Checkbox,
    DescriptionList,
    ErrorMessage,
    Paragraph,
    Spinner,
    Table,
} from '@vwfs-bronson/bronson-react';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import {
    DividedContractHeader,
    Notification,
    NotificationStatus,
    TrackingId,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    formatAsCurrency,
    formatAsDate,
    getProposeOutstandingPaymentEndpoint,
    OutstandingPaymentsBO,
    ProposeOutstandingPaymentError,
    ProposeOutstandingPaymentRequest,
    ProposeOutstandingPaymentResponse,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import {
    contractCategory,
    parseErrorResponse,
    textWithComponents,
    vehicleInformation,
    useRealex,
    buildLinkToPhoneNumber,
} from 'utils';
import { LicensePlate } from 'components/contracts-overview/LicensePlate';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import { fetchOutstandingPayments } from './OutstandingPaymentsSlice';
import { selectOutstandingPaymentsForSpecific } from './OutstandingPaymentsSelector';
import { AxiosError, AxiosResponse } from 'axios';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import {
    BrowserAwarenessModal,
    PaymentFailedModal,
    PaymentGatewayErrorModal,
    PaymentStaleModal,
    PaymentSuccessfulModal,
    MaximumPaymentExceededModal,
    RealexFailedModalStatus,
} from 'components/realex-payments';

const noneSelectedIndex = -1;

export const OutstandingPaymentsUi: React.FC<{
    encryptedContractId: string;
    outstandingPayments: OutstandingPaymentsBO | undefined;
}> = ({ encryptedContractId, outstandingPayments }) => {
    const totalArrears = outstandingPayments?.totalArrears ?? 0.0;
    const items = outstandingPayments?.items ?? [];
    const maximumPayment = outstandingPayments?.maximumPayment ?? 0.0;

    const [trackingId, setTrackingId] = useState<TrackingId>('onPayment');
    const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(noneSelectedIndex);
    const [totalSelected, setTotalSelected] = useState<number>(0.0);
    const [totalRemaining, setTotalRemaining] = useState<number>(totalArrears);
    const [showPaymentStaleModal, setShowPaymentStaleModal] = useState<boolean>(false);
    const [showMaximumPaymentExceededModal, setShowMaximumPaymentExceededModal] = useState<boolean>(false);
    const [showBrowserAwarenessModal, setShowBrowserAwarenessModal] = useState<boolean>(false);
    const [showPaymentGatewayErrorModal, setShowPaymentGatewayErrorModal] = useState<boolean>(false);
    const [showPaymentSuccessfulModal, setShowPaymentSuccessfulModal] = useState<boolean>(false);
    const [showPaymentFailedModal, setShowPaymentFailedModal] = useState<boolean>(false);
    const [showSelectAtLeastOnePayment, setShowSelectAtLeastOnePayment] = useState<boolean>(false);
    const [paymentFailedModalStatus, setPaymentFailedModalStatus] = useState<RealexFailedModalStatus>('warning');
    const [paymentFailedModalKey, setPaymentFailedModalKey] = useState<string>('defaultError');
    const [isProposeSubmitting, setIsProposeSubmitting] = useState<boolean>(false);
    const [isProcessSubmitting, setIsProcessSubmitting] = useState<boolean>(false);
    const [isCancelSubmitting, setIsCancelSubmitting] = useState<boolean>(false);
    const paymentIdentifierRef = useRef<string | undefined>(undefined);
    const { t } = useTranslation('outstanding-payments');
    const history = useHistory();
    const { setupRealex, cancelPayment } = useRealex(
        'outstanding-payment',
        'paymentSuccess',
        'paymentError',
        setTrackingId,
        setShowPaymentSuccessfulModal,
        setShowPaymentFailedModal,
        setPaymentFailedModalStatus,
        setPaymentFailedModalKey,
        setIsProcessSubmitting,
        setIsCancelSubmitting,
    );

    const { onAction: onPayNowAction } = useAnalyticsActionTracker('onPay');

    useAnalyticsPageViewTracker(trackingId, !!outstandingPayments);

    const isAnySubmitting = isProposeSubmitting || isProcessSubmitting || isCancelSubmitting;

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    const proposePayment = (): Promise<void> => {
        if (isAnySubmitting || !!paymentIdentifierRef.current || lastSelectedIndex === noneSelectedIndex) {
            return Promise.resolve();
        }

        const proposePaymentRequest: ProposeOutstandingPaymentRequest = {
            documentNumbers: items.slice(0, lastSelectedIndex + 1).map(({ documentNumber }) => documentNumber),
        };

        setIsProposeSubmitting(true);
        return CpDataApi.post<ProposeOutstandingPaymentResponse>(
            getProposeOutstandingPaymentEndpoint(encryptedContractId),
            proposePaymentRequest,
        )
            .then(({ data }: AxiosResponse<ProposeOutstandingPaymentResponse>): void => {
                setIsProposeSubmitting(false);
                setupRealex(data);
            })
            .catch((error: AxiosError<ProposeOutstandingPaymentResponse>): void => {
                const supportedCodes: string[] = [
                    'PAYMENT_ITEMS_STALE',
                    'PAYMENT_AMOUNT_TOO_LOW',
                    'PAYMENT_AMOUNT_TOO_HIGH',
                ];

                const parsedError = parseErrorResponse<ProposeOutstandingPaymentError>(error);
                if (parsedError?.status === 502 && supportedCodes.includes(parsedError?.code)) {
                    if (parsedError.code === 'PAYMENT_ITEMS_STALE') {
                        setShowPaymentStaleModal(true);
                    } else if (parsedError.code === 'PAYMENT_AMOUNT_TOO_LOW') {
                        setShowSelectAtLeastOnePayment(true);
                    } else {
                        setShowMaximumPaymentExceededModal(true);
                    }
                } else {
                    setShowPaymentGatewayErrorModal(true);
                }
                setIsProposeSubmitting(false);
            });
    };

    useEffect((): void => {
        const newTotalSelected = items
            .slice(0, lastSelectedIndex + 1)
            .reduce((accumulator, { amount }) => accumulator + amount, 0.0);
        const newTotalRemaining = totalArrears - newTotalSelected;
        setTotalSelected(newTotalSelected);
        setTotalRemaining(newTotalRemaining);
        if (newTotalSelected > maximumPayment) {
            setShowMaximumPaymentExceededModal(true);
        }
    }, [totalArrears, items, lastSelectedIndex, maximumPayment]);

    if (outstandingPayments === undefined) {
        return null;
    }

    const {
        vehicleBrand,
        vehicleDescription,
        productType,
        contractNumber,
        vehicleRegistrationNumber,
        totalPayable,
    } = outstandingPayments;

    const rightSideContent = (
        <span>
            <DescriptionList horizontal table testId={'rightSideContent'}>
                <DescriptionTermAndDetail
                    testId={'totalArrears'}
                    term={t('items.totalArrears')}
                    detail={formatAsCurrency(totalArrears)}
                />
                <DescriptionTermAndDetail
                    testId={'totalPayable'}
                    term={t('items.totalPayable')}
                    termClassName={'u-text-bold'}
                    detail={formatAsCurrency(totalPayable)}
                />
            </DescriptionList>
        </span>
    );

    const onPayableItemChange = (index: number): void => {
        const newSelectedIndex = lastSelectedIndex < index ? index : index - 1;
        setLastSelectedIndex(newSelectedIndex);
        setShowSelectAtLeastOnePayment(false);
    };

    const onBack = async (): Promise<void> => {
        if (isAnySubmitting || !!paymentIdentifierRef.current) {
            return Promise.resolve();
        }

        await cancelPayment();
        history.push(dashboardPagePath());
        return Promise.resolve();
    };
    const onProceedToPaymentClick = (): void => {
        if (lastSelectedIndex === noneSelectedIndex) {
            setShowSelectAtLeastOnePayment(true);
            return;
        }

        setShowBrowserAwarenessModal(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onIgnoreClose = (): void => {};

    const onPaymentItemsStaleConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onMaximumPaymentExceededConfirm = (): void => {
        setShowMaximumPaymentExceededModal(false);

        let totalAmountSoFar = 0.0;
        items.some(({ amount }, index) => {
            totalAmountSoFar += amount;
            if (totalAmountSoFar > maximumPayment) {
                setLastSelectedIndex(index - 1);
                return true;
            }
            return false;
        });
    };

    const onBrowserAwarenessClose = (): void => {
        setShowBrowserAwarenessModal(false);
    };
    const onBrowserAwarenessConfirm = (): Promise<void> => {
        setShowBrowserAwarenessModal(false);
        onPayNowAction();

        return proposePayment();
    };

    const onPaymentGatewayErrorModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onPaymentSuccessfulModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onPaymentFailedModalConfirm = (): void => {
        setShowPaymentFailedModal(false);
        setTrackingId('onPayment');
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {items.length === 0 ? (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('noItemsNotification.headline')}
                    text={t('noItemsNotification.text')}
                    testId={'noItemsNotification'}
                />
            ) : (
                <Fragment>
                    <DividedContractHeader
                        carInformation={vehicleInformation(vehicleBrand, vehicleDescription)}
                        contractCategory={contractCategory(productType)}
                        contractNumber={contractNumber}
                        contractIdentifier={
                            <LicensePlate vehicleRegistrationNumber={vehicleRegistrationNumber || t('unknown')} />
                        }
                        rightSideContent={rightSideContent}
                    />
                    <Paragraph testId={'introductionParagraph1'} className={'u-mt'}>
                        {t('paragraphs.introduction1')}
                    </Paragraph>
                    <Paragraph testId={'introductionParagraph2'}>{t('paragraphs.introduction2')}</Paragraph>
                    {isAnySubmitting && <Spinner fullPage />}
                    <Table className={'u-mb-none'} data-testid={'paymentsTable'} noScroll>
                        <Table.Thead>
                            <Table.Tr data-testid={'headerRow'}>
                                <Table.Th className={'is-first-child'}>&nbsp;</Table.Th>
                                <Table.Th className={'u-hide@xs'}>{t('tableHeadings.paymentDueDate')}</Table.Th>
                                <Table.Th>{t('tableHeadings.cashFlowType')}</Table.Th>
                                <Table.Th className={'u-text-right is-last-child'}>{t('tableHeadings.amount')}</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {items.map(({ documentNumber, paymentDueDate, cashFlowType, amount }, index) => (
                                <Table.Tr key={documentNumber} data-testid={`bodyItem${index}Row`}>
                                    <Table.Td className={'is-first-child'}>
                                        <Checkbox
                                            data-testid={`bodyItem${index}Checkbox`}
                                            checked={index <= lastSelectedIndex}
                                            onChange={(): void => onPayableItemChange(index)}
                                        />
                                    </Table.Td>
                                    <Table.Td className={'u-hide@xs'}>
                                        <span>{formatAsDate(paymentDueDate)}</span>
                                    </Table.Td>
                                    <Table.Td>
                                        <span className={'u-hide@xs-up u-block u-font-size-fs-2'}>
                                            {formatAsDate(paymentDueDate)}
                                        </span>
                                        <span className={'u-block'}>{cashFlowType}</span>
                                    </Table.Td>
                                    <Table.Td className={'u-text-right is-last-child'}>{formatAsCurrency(amount)}</Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                        <Table.Tfoot>
                            <Table.Tr data-testid={'footerTotalSelectedRow'}>
                                <Table.Td
                                    className={'u-hide@xs u-text-bold u-text-white is-first-child'}
                                    colSpan={3}
                                    data-theme={'brand'}
                                >
                                    {t('tableFooters.totalSelected')}
                                </Table.Td>
                                <Table.Td
                                    className={'u-hide@xs-up u-text-bold u-text-white is-first-child'}
                                    colSpan={2}
                                    data-theme={'brand'}
                                >
                                    {t('tableFooters.totalSelected')}
                                </Table.Td>
                                <Table.Td
                                    data-testid={'footerTotalSelectedDetail'}
                                    className={'u-text-right u-text-bold u-text-white is-last-child'}
                                    data-theme={'brand'}
                                >
                                    {formatAsCurrency(totalSelected)}
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr data-testid={'footerTotalRemainingRow'}>
                                <Table.Td className={'u-hide@xs u-text-bold is-first-child'} colSpan={3}>
                                    {t('tableFooters.totalRemaining')}
                                </Table.Td>
                                <Table.Td className={'u-hide@xs-up u-text-bold is-first-child'} colSpan={2}>
                                    {t('tableFooters.totalRemaining')}
                                </Table.Td>
                                <Table.Td
                                    data-testid={'footerTotalRemainingDetail'}
                                    className={'u-text-right u-text-bold is-last-child'}
                                >
                                    {formatAsCurrency(totalRemaining)}
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tfoot>
                    </Table>
                    {showSelectAtLeastOnePayment && (
                        <ErrorMessage testId={'mustSelectAtLeastOnePayment'}>
                            {t('errorMessages.mustSelectAtLeastOnePayment')}
                        </ErrorMessage>
                    )}
                    <Paragraph testId={'notesParagraph1'} className={'u-mt'}>
                        {t('paragraphs.notes1')}
                    </Paragraph>
                    <Paragraph testId={'notesParagraph2'}>{textWithComponents(t, 'paragraphs.notes2', { linkToCollectionsPhoneNumber })}</Paragraph>
                    <Paragraph testId={'termsAndConditionsTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.termsAndConditionsTitle')}
                    </Paragraph>
                    <Paragraph testId={'termsAndConditionsDetailsParagraph'}>
                        {t('paragraphs.termsAndConditionsDetails')}
                    </Paragraph>
                    <Paragraph testId={'payingByCreditCardTitleParagraph'} className={'u-text-bold'}>
                        {textWithComponents(t, 'paragraphs.payingByCreditCardTitle')}
                    </Paragraph>
                    <Paragraph testId={'payingByCreditCardDetails1Paragraph'}>
                        {t('paragraphs.payingByCreditCardDetails1')}
                    </Paragraph>
                    <Paragraph testId={'payingByCreditCardDetails2Paragraph'}>
                        {t('paragraphs.payingByCreditCardDetails2')}
                    </Paragraph>
                    <ButtonContainer nav>
                        <Button
                            type={'button'}
                            disabled={isAnySubmitting}
                            onClick={onBack}
                            secondary
                            testId={'backButton'}
                        >
                            {t('translation:editableSectionNav.back')}
                        </Button>
                        <Button
                            type={'button'}
                            disabled={isAnySubmitting}
                            onClick={onProceedToPaymentClick}
                            testId={'proceedToPaymentButton'}
                        >
                            {t('buttons.proceedToPayment.label')}
                        </Button>
                    </ButtonContainer>
                    <PaymentStaleModal
                        shown={showPaymentStaleModal}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentItemsStaleConfirm}
                        context={'outstandingPayments'}
                    ></PaymentStaleModal>
                    <MaximumPaymentExceededModal
                        shown={showMaximumPaymentExceededModal}
                        maximumPayment={maximumPayment}
                        onClose={onIgnoreClose}
                        onConfirm={onMaximumPaymentExceededConfirm}
                    ></MaximumPaymentExceededModal>
                    <BrowserAwarenessModal
                        shown={showBrowserAwarenessModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onBrowserAwarenessClose}
                        onConfirm={onBrowserAwarenessConfirm}
                    ></BrowserAwarenessModal>
                    <PaymentGatewayErrorModal
                        shown={showPaymentGatewayErrorModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentGatewayErrorModalConfirm}
                    ></PaymentGatewayErrorModal>
                    <PaymentSuccessfulModal
                        shown={showPaymentSuccessfulModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentSuccessfulModalConfirm}
                        context={'outstandingPayments'}
                    ></PaymentSuccessfulModal>
                    <PaymentFailedModal
                        shown={showPaymentFailedModal}
                        confirmationDisabled={isAnySubmitting}
                        errorKey={paymentFailedModalKey}
                        status={paymentFailedModalStatus}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentFailedModalConfirm}
                    ></PaymentFailedModal>
                </Fragment>
            )}
        </Suspense>
    );
};

const OutstandingPaymentsWithHandlers = withLoadingAndNoConnectionHandler(OutstandingPaymentsUi);

export const OutstandingPayments: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: outstandingPayments, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchOutstandingPayments,
        selectOutstandingPaymentsForSpecific,
        encryptedContractId,
        true,
    );

    return (
        <OutstandingPaymentsWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            outstandingPayments={outstandingPayments}
        />
    );
};
