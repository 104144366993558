import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Heading, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    TrackingId,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    FinanceBrandsEOC,
    getRequestAgreementExtensionEndpoint,
    ProductTypesEOC,
    RequestAgreementExtension,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    EmailAddressNotification,
    EmailAddressNotificationMode,
    getEmailAddressNotificationMode,
} from 'components/notifications/email-address/EmailAddressNotification';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import {
    RadioButtonProps,
    ValidatedRadioButtonGroup,
} from 'components/validated-radio-button-group/ValidatedRadioButtonGroup';
import { ValidatedSelect, ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';
import { dashboardPagePath } from 'components/navigation/paths';
import { buildLinkToPhoneNumber, contractCategory, textWithComponents, useFinanceCompanyDetails } from 'utils';
import {
    ButtonsDA,
    ButtonsNY,
    ContractHireExtensionViewFormValues,
    ExtensionMonths,
    NewAgreementFinanceBrands,
    ChangeInCircumstances,
} from './types';
import {
    buttonsToBoolean,
    buttonsToDisagreeOrAgree,
    extensionMonthsToNumber,
    newAgreementFinanceBrandDisabled,
    newAgreementFinanceBrandIsMandatory,
    newAgreementProposalNumberDisabled,
    show7To12MonthsParagraph,
    getFormNameForAnalytics,
    changeInCircumstanceIsMandatory,
    changeInCircumstanceDisabled,
} from './helpers';
import { validationSchema } from './ContractHireExtensionValidation';

export type ContractHireExtensionViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    contractNumber: string;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    registrationNumber: string;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    inArrears: boolean;
    minValidCurrentMileage: number;
    maxValidCurrentMileage: number;
    contractedMileage: number;
    onStart: () => void;
    onBack: () => void;
    onCallBack: () => void;
};

export const ContractHireExtensionView: React.FC<ContractHireExtensionViewProps> = ({
    encryptedContractId,
    contractDescription,
    contractNumber,
    financeBrand,
    vehicleBrand,
    registrationNumber,
    firstName,
    lastName,
    emailAddress,
    inArrears,
    minValidCurrentMileage,
    maxValidCurrentMileage,
    contractedMileage,
    onStart,
    onBack,
    onCallBack,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const { t } = useTranslation('end-of-contract-contract-hire-extension-view');
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [trackingId, setTrackingId] = useState<TrackingId>('endOfContractRchExtension');
    const { phoneNumber, openingHours } = useFinanceCompanyDetails(productType, financeBrand);

    // TODO: Analytics...
    //const { onAction: onIndependentSupportAction } = useAnalyticsActionTracker('onEndOfContractRchExtensionIndependentSupportAction');
    //const { onAction: onStartAction } = useAnalyticsActionTracker('onEndOfContractRchExtensionStart');
    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractRchExtensionBack');
    const { onAction: onRequestAction } = useAnalyticsActionTracker('onEndOfContractRchExtensionRequest');
    useAnalyticsPageViewTracker(trackingId);

    const onIndependentSupportAction = (independentSupportUrl: string) => {
        console.log(`[ContractHireExtensionView] onIndependentSupport(${independentSupportUrl})...`);
    };
    const onStartAction = () => {
        console.log(`[ContractHireExtensionView] onStartAction()...`);
    };

    const requestDescription = t('notifications.emailAddress.requestDescription');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    const emailAddressNotificationMode = getEmailAddressNotificationMode(emailAddress);
    if (emailAddressNotificationMode !== EmailAddressNotificationMode.CheckEmailAddress) {
        return <EmailAddressNotification mode={emailAddressNotificationMode} requestDescription={requestDescription} />;
    }

    const moneyHelperUrl = t('notifications.independentSupport.moneyHelperUrl');
    const moneyNavigatorToolUrl = t('notifications.independentSupport.moneyNavigatorToolUrl');
    const citizensAdviceOnlineUrl = t('notifications.independentSupport.citizensAdviceOnlineUrl');

    const noAndYesRadioButtons = (dagger: boolean): RadioButtonProps[] => {
        const noSuffix = dagger ? ' ‡' : '';
        return [
            { label: `${t(`noOrYesRadioButtons.${ButtonsNY.no}`)}${noSuffix}`, value: ButtonsNY.no },
            { label: t(`noOrYesRadioButtons.${ButtonsNY.yes}`), value: ButtonsNY.yes },
        ];
    };

    const disagreeAndAgreeRadioButtons = (): RadioButtonProps[] => {
        return [
            { label: t(`disagreeOrAgreeRadioButtons.${ButtonsDA.disagree}`), value: ButtonsDA.disagree },
            { label: t(`disagreeOrAgreeRadioButtons.${ButtonsDA.agree}`), value: ButtonsDA.agree },
        ];
    };

    const newAgreementFinanceBrandLabel = (key: string): string => {
        return t(`newAgreementFinanceBrand.${key}`);
    };

    const newAgreementFinanceBrandSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(NewAgreementFinanceBrands).map(([, value]) => {
            return {
                label: newAgreementFinanceBrandLabel(value),
                value,
            };
        });
    };

    const extensionMonthsSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(ExtensionMonths).map(([, value]) => {
            return {
                label: t(`extensionMonths.${value}`),
                value,
            };
        });
    };

    const changeInCircumstanceLabel = (key: string): string => {
        return t(`changeInCircumstance.${key}`);
    };

    const changeInCircumstanceSelectItems = (): ValidatedSelectItem[] => {
        return Object.entries(ChangeInCircumstances).map(([, value]) => {
            return {
                label: changeInCircumstanceLabel(value),
                value,
            };
        });
    };

    const initialValues: ContractHireExtensionViewFormValues = {
        alreadyHaveNewAgreement: '',
        newAgreementFinanceBrand: '',
        newAgreementProposalNumber: '',
        extensionMonths: '',
        currentMileage: '',
        canAffordWithCurrentLevelsOfIncomeAndExpenditure: '',
        incomeNotExpectedToChange: '',
        expenditureNotExpectedToChange: '',
        changeInCircumstance: '',
    };

    const onMoneyHelperClick = (): void => {
        onIndependentSupportAction(moneyHelperUrl);
    };
    const onMoneyNavigatorToolClick = (): void => {
        onIndependentSupportAction(moneyNavigatorToolUrl);
    };
    const onCitizensAdviceOnlineClick = (): void => {
        onIndependentSupportAction(citizensAdviceOnlineUrl);
    };

    const linkToMoneyHelper = (
        <SimpleLink
            href={moneyHelperUrl}
            target={'_BLANK'}
            onClick={onMoneyHelperClick}
            testId={'moneyHelperLink'}
        />
    );
    const linkToMoneyNavigatorTool = (
        <SimpleLink
            href={moneyNavigatorToolUrl}
            target={'_BLANK'}
            onClick={onMoneyNavigatorToolClick}
            testId={'moneyNavigatorToolLink'}
        />
    );
    const linkToCitizensAdviceOnline = (
        <SimpleLink
            href={citizensAdviceOnlineUrl}
            target={'_BLANK'}
            onClick={onCitizensAdviceOnlineClick}
            testId={'citizensAdviceOnlineLink'}
        />
    );

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onAlreadyHaveNewAgreementChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        { setFieldValue, setFieldTouched }: FormikProps<ContractHireExtensionViewFormValues>,
    ): void => {
        if (newAgreementFinanceBrandDisabled(newValue)) {
            setFieldValue('newAgreementFinanceBrand', '', false);
            setFieldTouched('newAgreementFinanceBrand', false, false);
        }

        if (newAgreementProposalNumberDisabled(newValue)) {
            setFieldValue('newAgreementProposalNumber', '', false);
            setFieldTouched('newAgreementProposalNumber', false, false);
        }

        setFieldValue('extensionMonths', '', false);
        setFieldTouched('extensionMonths', false, false);
    };

    const resetChangeInCircumstance = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<ContractHireExtensionViewFormValues>): void => {
        setFieldValue('changeInCircumstance', '', false);
        setFieldTouched('changeInCircumstance', false, false);
    };

    const onCanAffordWithCurrentLevelsOfIncomeAndExpenditureChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<ContractHireExtensionViewFormValues>,
    ): void => {
        const {
            values: { incomeNotExpectedToChange, expenditureNotExpectedToChange },
        } = formik;

        if (changeInCircumstanceDisabled(newValue, incomeNotExpectedToChange, expenditureNotExpectedToChange)) {
            resetChangeInCircumstance(formik);
        }
    };

    const onIncomeNotExpectedToChangeChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<ContractHireExtensionViewFormValues>,
    ): void => {
        const {
            values: { canAffordWithCurrentLevelsOfIncomeAndExpenditure, expenditureNotExpectedToChange },
        } = formik;

        if (
            changeInCircumstanceDisabled(
                canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                newValue,
                expenditureNotExpectedToChange,
            )
        ) {
            resetChangeInCircumstance(formik);
        }
    };

    const onExpenditureNotExpectedToChangeChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<ContractHireExtensionViewFormValues>,
    ): void => {
        const {
            values: { canAffordWithCurrentLevelsOfIncomeAndExpenditure, incomeNotExpectedToChange },
        } = formik;

        if (
            changeInCircumstanceDisabled(
                canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                incomeNotExpectedToChange,
                newValue,
            )
        ) {
            resetChangeInCircumstance(formik);
        }
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
        setTrackingId('endOfContractRchExtension');
    };

    const onEndOfContractOptions = (): void => {
        onStartAction();
        onStart();
    };

    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };

    const onCallBackClick = (): void => {
        onCallBack();
    };

    return (
        <View testId={'contractHireExtensionView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType) })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <EmailAddressNotification
                className={'u-mb'}
                mode={emailAddressNotificationMode}
                requestDescription={requestDescription}
                emailAddress={emailAddress}
            />
            <Paragraph testId={'completeFollowingParagraph'}>
                {t('paragraphs.completeFollowing', { vehicleBrand })}
            </Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, minValidCurrentMileage, maxValidCurrentMileage, contractNumber)}
                onSubmit={({
                    alreadyHaveNewAgreement,
                    newAgreementFinanceBrand,
                    newAgreementProposalNumber,
                    extensionMonths,
                    currentMileage,
                    canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                    incomeNotExpectedToChange,
                    expenditureNotExpectedToChange,
                    changeInCircumstance,
                }: ContractHireExtensionViewFormValues): Promise<void> => {
                    const requestAgreementExtension: RequestAgreementExtension = {
                        registrationNumber,
                        firstName,
                        lastName,
                        emailAddress: emailAddress ?? '',
                        alreadyHaveNewAgreement: buttonsToBoolean(alreadyHaveNewAgreement),
                        newAgreementFinanceBrand: newAgreementFinanceBrand
                            ? newAgreementFinanceBrandLabel(newAgreementFinanceBrand)
                            : '',
                        newAgreementProposalNumber,
                        extensionMonths: extensionMonthsToNumber(extensionMonths),
                        currentMileage: Number(currentMileage),
                        withinContractMileage: Number(currentMileage) <= contractedMileage,
                        inArrears,
                        canAffordWithCurrentLevelsOfIncomeAndExpenditure: buttonsToDisagreeOrAgree(
                            canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                        ),
                        incomeNotExpectedToChange: buttonsToDisagreeOrAgree(incomeNotExpectedToChange),
                        expenditureNotExpectedToChange: buttonsToDisagreeOrAgree(expenditureNotExpectedToChange),
                        changeInCircumstance: changeInCircumstance
                            ? changeInCircumstanceLabel(changeInCircumstance)
                            : '',
                    };
                    const formNameForAnalytics = getFormNameForAnalytics(requestAgreementExtension);
                    onRequestAction(formNameForAnalytics);
                    setIsSubmitting(true);
                    return CpDataApi.post<void>(
                        getRequestAgreementExtensionEndpoint(encryptedContractId),
                        requestAgreementExtension,
                    )
                        .then(() => {
                            setShowSuccessModal(true);
                            setTrackingId('endOfContractRchExtensionSuccess');
                            setIsSubmitting(false);
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setTrackingId('endOfContractRchExtensionFailure');
                            setIsSubmitting(false);
                        });
                }}
            >
                {(formik: FormikProps<ContractHireExtensionViewFormValues>): React.ReactNode => (
                    <Form className={'uk-end-of-contract-contract-hire-extension-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    className={'u-mb-xsmall'}
                                    label={t('alreadyHaveNewAgreement.label')}
                                    tooltip={t('alreadyHaveNewAgreement.tooltip')}
                                    radioButtons={noAndYesRadioButtons(true)}
                                    name={'alreadyHaveNewAgreement'}
                                    testId={'alreadyHaveNewAgreement'}
                                    isMandatory
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onAlreadyHaveNewAgreementChange(e, formik);
                                    }}
                                />
                                <div className={'u-indent'}>
                                    <ValidatedSelect
                                        className={'u-mb-xsmall select-finance-brand'}
                                        label={t('newAgreementFinanceBrand.label')}
                                        placeholder={t('newAgreementFinanceBrand.placeholder')}
                                        selectItems={newAgreementFinanceBrandSelectItems()}
                                        name={'newAgreementFinanceBrand'}
                                        testId={'newAgreementFinanceBrand'}
                                        isMandatory={newAgreementFinanceBrandIsMandatory(
                                            formik.values.alreadyHaveNewAgreement,
                                        )}
                                        disabled={newAgreementFinanceBrandDisabled(
                                            formik.values.alreadyHaveNewAgreement,
                                        )}
                                    />
                                    <ValidatedInputWithCleave
                                        className={'input-proposal-number'}
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [12],
                                            delimiter: '',
                                        }}
                                        label={t('newAgreementProposalNumber.label')}
                                        placeholder={t('newAgreementProposalNumber.placeholder')}
                                        tooltip={t('newAgreementProposalNumber.tooltip')}
                                        name={'newAgreementProposalNumber'}
                                        testId={'newAgreementProposalNumber'}
                                        isMandatory={false}
                                        disabled={newAgreementProposalNumberDisabled(
                                            formik.values.alreadyHaveNewAgreement,
                                        )}
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-extension-months'}
                                    label={t('extensionMonths.label')}
                                    tooltip={t('extensionMonths.tooltip')}
                                    placeholder={t('extensionMonths.placeholder')}
                                    selectItems={extensionMonthsSelectItems()}
                                    name={'extensionMonths'}
                                    testId={'extensionMonths'}
                                    isMandatory
                                />
                                {show7To12MonthsParagraph(formik.values.extensionMonths) && (
                                    <Paragraph
                                        className={'u-mt-xsmall u-mb-none u-font-size-fs-1'}
                                        testId={'7To12MonthsParagraph'}
                                    >
                                        {t('paragraphs.7to12Months')}
                                    </Paragraph>
                                )}
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [6],
                                        delimiter: '',
                                    }}
                                    className={'input-mileage'}
                                    label={t('currentMileage.label')}
                                    tooltip={t('currentMileage.tooltip')}
                                    name={'currentMileage'}
                                    testId={'currentMileage'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    label={t('canAffordWithCurrentLevelsOfIncomeAndExpenditure.label')}
                                    radioButtons={disagreeAndAgreeRadioButtons()}
                                    name={'canAffordWithCurrentLevelsOfIncomeAndExpenditure'}
                                    testId={'canAffordWithCurrentLevelsOfIncomeAndExpenditure'}
                                    isMandatory
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onCanAffordWithCurrentLevelsOfIncomeAndExpenditureChange(e, formik);
                                    }}
                                />
                                <div className={'u-mt-xsmall'}>
                                    <ValidatedRadioButtonGroup
                                        label={t('incomeNotExpectedToChange.label')}
                                        radioButtons={disagreeAndAgreeRadioButtons()}
                                        name={'incomeNotExpectedToChange'}
                                        testId={'incomeNotExpectedToChange'}
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onIncomeNotExpectedToChangeChange(e, formik);
                                        }}
                                    />
                                </div>
                                <div className={'u-mt-xsmall'}>
                                    <ValidatedRadioButtonGroup
                                        label={t('expenditureNotExpectedToChange.label')}
                                        radioButtons={disagreeAndAgreeRadioButtons()}
                                        name={'expenditureNotExpectedToChange'}
                                        testId={'expenditureNotExpectedToChange'}
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onExpenditureNotExpectedToChangeChange(e, formik);
                                        }}
                                    />
                                </div>
                                <div className={'u-indent'}>
                                    <ValidatedSelect
                                        className={'u-mt-xsmall select-change-in-circumstance'}
                                        label={t('changeInCircumstance.label')}
                                        placeholder={t('changeInCircumstance.placeholder')}
                                        selectItems={changeInCircumstanceSelectItems()}
                                        name={'changeInCircumstance'}
                                        testId={'changeInCircumstance'}
                                        helpText={t('changeInCircumstance.helpText')}
                                        isMandatory={changeInCircumstanceIsMandatory(
                                            formik.values.canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                                            formik.values.incomeNotExpectedToChange,
                                            formik.values.expenditureNotExpectedToChange,
                                        )}
                                        disabled={changeInCircumstanceDisabled(
                                            formik.values.canAffordWithCurrentLevelsOfIncomeAndExpenditure,
                                            formik.values.incomeNotExpectedToChange,
                                            formik.values.expenditureNotExpectedToChange,
                                        )}
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Notification
                                    status={NotificationStatus.warning}
                                    text={t('notifications.independentSupport.text')}
                                    testId={'independentSupportNotification'}
                                >
                                    <Paragraph
                                        className={'u-mv-xsmall u-text-bold'}
                                        testId={'independentSupportNotificationFreeSupportParagraph'}
                                    >
                                        {t('notifications.independentSupport.freeSupport')}
                                    </Paragraph>
                                    <Paragraph
                                        className={'u-mb-xsmall'}
                                        testId={'independentSupportNotificationMoneyHelperParagraph'}
                                    >
                                        {textWithComponents(t, 'notifications.independentSupport.moneyHelper', {
                                            linkToMoneyHelper,
                                            linkToMoneyNavigatorTool,
                                        })}
                                    </Paragraph>
                                    <Paragraph
                                        className={'u-mb-xsmall'}
                                        testId={'independentSupportNotificationCitizensAdviceParagraph'}
                                    >
                                        {textWithComponents(t, 'notifications.independentSupport.citizensAdvice', {
                                            linkToCitizensAdviceOnline,
                                        })}
                                    </Paragraph>
                                    <Paragraph
                                        className={'u-mb-xsmall'}
                                        testId={'independentSupportNotificationExtensionNotRightParagraph'}
                                    >
                                        {t('notifications.independentSupport.extensionNotRight')}
                                    </Paragraph>
                                    <Button
                                        type={'button'}
                                        testId={'endOfContractOptions'}
                                        onClick={onEndOfContractOptions}
                                        secondary
                                    >
                                        {t('buttons.endOfContractOptions')}
                                    </Button>
                                </Notification>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'pleaseNoteParagraph'}>{t('paragraphs.pleaseNote')}</Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'backButton'}
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        testId={'requestButton'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'contactUsParagraph'}>
                                    {textWithComponents(
                                        t,
                                        'paragraphs.contactUs',
                                        { linkToPhoneNumber },
                                        { vehicleBrand, phoneNumber, openingHours },
                                    )}
                                </Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'callBackParagraph'}>
                                    {textWithComponents(t, 'paragraphs.requestCallBack')}
                                </Paragraph>
                                <Button
                                    type={'button'}
                                    testId={'requestCallBackButton'}
                                    onClick={onCallBackClick}
                                    secondary
                                >
                                    {t('buttons.requestCallBack')}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{t('successModal.contents')}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </View>
    );
};
