import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { currentBrandName } from '../../config';
import { useTranslation } from 'react-i18next';

export type ExternalWebsitesNotificationProps = {
    className?: string;
    externalWebsitesNotification: string;
};

export const ExternalWebsitesNotification: React.FC<ExternalWebsitesNotificationProps> = ({
    className,
    externalWebsitesNotification,
}) => {
    const { t } = useTranslation('financial-support-and-wellbeing-external-websites-notification');

    if (!externalWebsitesNotification) {
        return null;
    }

    return (
        <Notification
            className={className}
            status={NotificationStatus.warning}
            text={t(`notifications.${externalWebsitesNotification}.text`, {
                brandName: currentBrandName,
            })}
            testId={'externalWebsitesNotification'}
        />
    );
};
