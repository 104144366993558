import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, Heading, Paragraph, UnorderedList } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO, FinanceBrandsEOC, ProductTypesEOC } from '@cp-uk/common';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { dashboardPagePath } from 'components/navigation/paths';
import { contractCategory, getMailtoHref, getTelHref, textWithComponents, useFinanceCompanyDetails } from 'utils';
import { carOrVehicleText, retailerDescriptionText } from '../../helpers';

export type ContractHireReturnViewProps = {
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    onBack: () => void;
    onCallBack: () => void;
};

export const ContractHireReturnView: React.FC<ContractHireReturnViewProps> = ({
    contractDescription,
    financeBrand,
    vehicleBrand,
    onBack,
    onCallBack,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation('end-of-contract-contract-hire-return-view');
    const history = useHistory();

    const { onAction: onBvrlaGuidelinesAction } = useAnalyticsActionTracker('onEndOfContractRchReturnBvrlaGuidelines');
    const { onAction: onEndOfContractChargesAction } = useAnalyticsActionTracker(
        'onEndOfContractRchReturnEndOfContractCharges',
    );
    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractRchReturnBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractRchReturnDashboard');
    useAnalyticsPageViewTracker('endOfContractRchReturn');

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);

    const bvrlaFairWearAndTearGuideUrl = t('paragraphs.inspectionAndCollection.bvrlaFairWearAndTearGuideUrl');
    const bcaBookingPortalUrl = t('paragraphs.inspectionAndCollection.bcaBookingPortalUrl');
    const bcaEmailAddress = getMailtoHref(t('paragraphs.inspectionAndCollection.bcaEmailAddress'));
    const bcaPhoneNumber = getTelHref(t('paragraphs.inspectionAndCollection.bcaPhoneNumber'));
    const bvrlaFairWearAndTearGuidelinesUrl = t('paragraphs.inspectionProcess.bvrlaFairWearAndTearGuidelinesUrl');
    const endOfContractChargesGuideForBrandUrl = t('paragraphs.damageOrMissing.endOfContractChargesGuideForBrandUrl', {
        financeBrand,
    });

    const onInspectionAndCollectionBvrlaFairWearAndTearGuideClick = (): void => {
        onBvrlaGuidelinesAction(bvrlaFairWearAndTearGuideUrl);
    };
    const onInspectionAndCollectionBritishCarAuctionBookingPortalClick = (): void => {
        onBvrlaGuidelinesAction(bcaBookingPortalUrl);
    };
    const onInspectionAndCollectionBritishCarAuctionBookingEmailClick = (): void => {
        onBvrlaGuidelinesAction(bcaEmailAddress);
    };
    const onInspectionAndCollectionBritishCarAuctionBookingPhoneClick = (): void => {
        onBvrlaGuidelinesAction(bcaPhoneNumber);
    };
    const onInspectionProcessBvrlaGuidelinesClick = (): void => {
        onBvrlaGuidelinesAction(bvrlaFairWearAndTearGuidelinesUrl);
    };
    const onDamageOrMissingEndOfContractChargesClick = (): void => {
        onEndOfContractChargesAction(endOfContractChargesGuideForBrandUrl);
    };
    const onCallBackClick = (): void => {
        onCallBack();
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    const linkToBvrlaFairWearAndTearGuide = (
        <SimpleLink
            href={bvrlaFairWearAndTearGuideUrl}
            target={'_BLANK'}
            onClick={onInspectionAndCollectionBvrlaFairWearAndTearGuideClick}
            testId={'inspectionAndCollectionBvrlaFairWearAndTearGuideLink'}
        />
    );
    const linkToBcaBookingPortal = (
        <SimpleLink
            href={bcaBookingPortalUrl}
            target={'_BLANK'}
            onClick={onInspectionAndCollectionBritishCarAuctionBookingPortalClick}
            testId={'inspectionAndCollectionBritishCarAuctionBookingPortalLink'}
        />
    );
    const linkToBCAPhoneNumber = (
        <SimpleLink
            href={bcaPhoneNumber}
            onClick={onInspectionAndCollectionBritishCarAuctionBookingPhoneClick}
            testId={'inspectionAndCollectionBritishCarAuctionBookingPhoneLink'}
        />
    );
    const linkToBcaEmailAddress = (
        <SimpleLink
            href={bcaEmailAddress}
            onClick={onInspectionAndCollectionBritishCarAuctionBookingEmailClick}
            testId={'inspectionAndCollectionBritishCarAuctionBookingEmailLink'}
        />
    );
    const linkToBvrlaFairWearAndTearGuidelines = (
        <SimpleLink
            href={bvrlaFairWearAndTearGuidelinesUrl}
            target={'_BLANK'}
            onClick={onInspectionProcessBvrlaGuidelinesClick}
            testId={'inspectionProcessBvrlaGuidelinesLink'}
        />
    );
    const linkToEndOfContractChargesGuide = (
        <SimpleLink
            href={endOfContractChargesGuideForBrandUrl}
            target={'_BLANK'}
            onClick={onDamageOrMissingEndOfContractChargesClick}
            testId={'damageOrMissingEndOfContractChargesLink'}
            download
        />
    );

    return (
        <View testId={'contractHireReturnView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'returnTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.return.title', { vehicleBrand })}
            </Paragraph>
            <Paragraph testId={'returnDetail1Paragraph'}>{t('paragraphs.return.detail1', { vehicleBrand })}</Paragraph>
            <Paragraph testId={'returnDetail2Paragraph'}>{t('paragraphs.return.detail2', { carOrVehicle })}</Paragraph>
            <Paragraph testId={'inspectionAndCollectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.inspectionAndCollection.title')}
            </Paragraph>
            <Paragraph testId={'inspectionAndCollectionDetail1Paragraph'}>
                {textWithComponents(
                    t,
                    'paragraphs.inspectionAndCollection.detail1',
                    { linkToBvrlaFairWearAndTearGuide },
                    { carOrVehicle },
                )}
            </Paragraph>
            <Paragraph testId={'inspectionAndCollectionDetail2Paragraph'}>
                {textWithComponents(t, 'paragraphs.inspectionAndCollection.detail2', {
                    linkToBcaBookingPortal,
                    linkToBcaEmailAddress,
                    linkToBCAPhoneNumber,
                })}
            </Paragraph>
            <Paragraph testId={'preparingForInspectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.preparingForInspection.title')}
            </Paragraph>
            <Paragraph testId={'preparingForInspectionDetail1Paragraph'}>
                {t('paragraphs.preparingForInspection.detail1')}
            </Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'preparingForInspectionEnsureThatList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.preparingForInspection.ensureThat.1', { carOrVehicle })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.preparingForInspection.ensureThat.2')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.preparingForInspection.ensureThat.3')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>
                        {t('listItems.preparingForInspection.ensureThat.4', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'preparingForInspectionDetail2Paragraph'}>
                {t('paragraphs.preparingForInspection.detail2')}
            </Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'preparingForInspectionHelpfulToKnowList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.1', {
                            carOrVehicle,
                            shortCompanyName,
                            retailerDescription,
                        })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.2')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.3', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'inspectionAndCollectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.inspectionProcess.title', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'inspectionProcessDetail1Paragraph'}>
                {textWithComponents(t, 'paragraphs.inspectionProcess.detail1', {
                    linkToBvrlaFairWearAndTearGuidelines,
                })}
            </Paragraph>
            <Paragraph testId={'inspectionProcessDetail2Paragraph'}>
                {t('paragraphs.inspectionProcess.detail2')}
            </Paragraph>
            <Paragraph testId={'inspectionProcessDetail3Paragraph'}>
                {t('paragraphs.inspectionProcess.detail3')}
            </Paragraph>
            <Paragraph testId={'damageOrMissingTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.damageOrMissing.title')}
            </Paragraph>
            <Paragraph testId={'damageOrMissingDetail1Paragraph'}>
                {textWithComponents(
                    t,
                    'paragraphs.damageOrMissing.detail1',
                    { linkToEndOfContractChargesDocument: linkToEndOfContractChargesGuide },
                    { carOrVehicle },
                )}
            </Paragraph>
            <Paragraph testId={'damageOrMissingDetail1Paragraph'}>
                {t('paragraphs.damageOrMissing.detail2', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'requestCallbackTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.requestCallback.title')}
            </Paragraph>
            <Paragraph testId={'requestCallbackDetail1Paragraph'}>
                {t('paragraphs.requestCallback.detail1', { vehicleBrand })}
            </Paragraph>
            <Paragraph>
                <Button type={'button'} testId={'requestCallBackButton'} secondary onClick={onCallBackClick}>
                    {t('buttons.requestCallback')}
                </Button>
            </Paragraph>
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
