import 'react-app-polyfill/ie11';
import MockAdapter from 'axios-mock-adapter';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';

import { currentBuild } from 'config';
import { CpContentApi, CpDataApi } from 'cp-xhr';
import 'localization/i18next';
import store from 'store';
import {
    getAddressDetailsEndpoint,
    getAddressSuggestionsEndpoint,
    getUserRegistryStatusEndpoint,
    getGreetingEndpoint,
    getOtherFinanceBrandsEndpoint,
    getContractsOverviewEndpoint,
    getContractDetailsEndpoint,
    getChangeAddressEndpoint,
    getChangeBankAccountEndpoint,
    getChangeContactDetailsEndpoint,
    getChangeMarketingPreferencesEndpoint,
    getChangeMileageEndpoint,
    getChangePaymentDateEndpoint,
    getChangeRegistrationNumberEndpoint,
    getDocumentCentreEndpoint,
    getDocumentListEndpoint,
    getMyProfileEndpoint,
    getOutstandingPaymentsEndpoint,
    getProposeOutstandingPaymentEndpoint,
    getProposeEarlySettlementEndpoint,
    getProcessPaymentEndpoint,
    getCancelPaymentEndpoint,
    getRequestContactEndpoint,
    getRequestAdditionalHelpEndpoint,
    getRequestAgreementExtensionEndpoint,
    getRequestBreathingSpaceEndpoint,
    getEarlySettlementEndpoint,
    getEarlyTerminationEndpoint,
    getEndOfContractEndpoint,
    getRequestRefinanceQuoteEndpoint,
    getRequestCallBackEndpoint,
    getStartMatchmakerEndpoint,
    setContentEndpointBuildTimestamp,
    getFinancialSupportAndWellbeingEndpoint,
    getInsuranceEndpoint,
    UK_TIMEZONE,
} from '@cp-uk/common';
import { AuthorizedUser } from 'auth/example/AuthorizedUserExampleData';
import { GreetingNormal } from 'components/greeting/ExampleData';
import { ContractsOverviewWithMultipleContracts } from 'components/contracts-overview/ExampleData';
import { ContractDetailsFinanceProduct, ContractDetailsServiceProduct } from 'components/contract-details/ExampleData';
import {
    AddressDetails123HighStreet,
    AddressDetails125HighStreet,
    AddressDetails127HighStreet,
    AddressSuggestionsNone,
    AddressSuggestionsMultiple,
    MyProfileNormal,
} from 'components/my-profile/ExampleData';
import {
    OutstandingPaymentsMultiple,
    proposeOutstandingPaymentResponse,
} from './components/outstanding-payments/ExampleData';
import { RequestContactTypical } from './components/request-contact/ExampleData';
import { RequestAdditionalHelpTypical } from './components/request-additional-help/ExampleData';
import {
    EarlySettlementExisting,
    EarlySettlementGenerate,
    proposeEarlySettlementResponse,
} from 'components/early-settlement/ExampleData';
import { EarlyTerminationExisting, EarlyTerminationGenerate } from 'components/early-termination/ExampleData';
import { EndOfContractSolutionsWithoutSellOptionAndShowMatchmaker } from 'components/end-of-contract/ExampleData';
import { DocumentCentreWithMultipleContracts } from './components/document-centre/ExampleData';
import { DocumentListMultiple } from './components/document-list/ExampleData';
import { WithSingleOtherBrand } from './components/other-finance-brands-card/ExampleData';
import { StartMatchmakerResponseTypical, StartMatchmakerTypical } from './components/start-matchmaker/ExampleData';
import { paymentIdentifier, ProcessPaymentTypical } from './components/realex-payments/ExampleData';
import {
    FinancialSupportAndWellbeingWithOneFpsContract
} from './components/financial-support-and-wellbeing/ExampleData';
import { InsuranceResponseWithLetUsKnowOption } from './components/insurance/ExampleData';

const render = () => {
    const App = require('./App').default;
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

if (process.env.NODE_ENV === 'development') {
    // Swap the value to enable local mocks
    const setupMockResponse = false;
    const cpDataApiMock = new MockAdapter(CpDataApi);
    const cpContentApiMock = new MockAdapter(CpContentApi);
    if (setupMockResponse) {
        const encryptedContract1Id = 'encrypted0000440123456789';
        const encryptedContract2Id = 'encrypted0000440123456790';
        cpDataApiMock.onGet(getUserRegistryStatusEndpoint()).reply(200, AuthorizedUser);
        cpDataApiMock.onGet(getGreetingEndpoint()).reply(200, GreetingNormal);
        cpDataApiMock.onGet(getOtherFinanceBrandsEndpoint()).reply(200, WithSingleOtherBrand);
        cpDataApiMock.onGet(getContractsOverviewEndpoint()).reply(200, ContractsOverviewWithMultipleContracts);
        cpDataApiMock.onGet(getContractDetailsEndpoint(encryptedContract1Id)).reply(200, ContractDetailsFinanceProduct);
        cpDataApiMock.onGet(getContractDetailsEndpoint(encryptedContract2Id)).reply(200, ContractDetailsServiceProduct);
        cpDataApiMock.onPut(getChangeBankAccountEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onPut(getChangePaymentDateEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onPost(getChangeMileageEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onPost(getChangeRegistrationNumberEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onGet(getMyProfileEndpoint()).reply(200, MyProfileNormal);
        cpDataApiMock.onGet(getAddressSuggestionsEndpoint('NB1 1AA')).reply(200, AddressSuggestionsMultiple);
        cpDataApiMock.onGet(getAddressSuggestionsEndpoint('NB1 1ZZ')).reply(200, AddressSuggestionsNone);
        cpDataApiMock.onGet(getAddressDetailsEndpoint('identity-1')).reply(200, AddressDetails123HighStreet);
        cpDataApiMock.onGet(getAddressDetailsEndpoint('identity-2')).reply(200, AddressDetails125HighStreet);
        cpDataApiMock.onGet(getAddressDetailsEndpoint('identity-3')).reply(200, AddressDetails127HighStreet);
        cpDataApiMock.onPut(getChangeAddressEndpoint()).reply(204, undefined);
        cpDataApiMock.onPut(getChangeContactDetailsEndpoint()).reply(204, undefined);
        cpDataApiMock.onPut(getChangeMarketingPreferencesEndpoint()).reply(204, undefined);
        cpDataApiMock.onGet(getOutstandingPaymentsEndpoint(encryptedContract1Id)).reply(200, OutstandingPaymentsMultiple);
        cpDataApiMock.onPost(getProposeOutstandingPaymentEndpoint(encryptedContract1Id)).reply(proposeOutstandingPaymentResponse);
        cpDataApiMock.onPost(getProposeEarlySettlementEndpoint(encryptedContract1Id)).reply(proposeEarlySettlementResponse);
        cpDataApiMock.onPost(getProcessPaymentEndpoint('outstanding-payment' ,paymentIdentifier)).reply(200, ProcessPaymentTypical);
        cpDataApiMock.onPost(getCancelPaymentEndpoint('outstanding-payment' ,paymentIdentifier)).reply(204, undefined);
        cpDataApiMock.onPost(getProcessPaymentEndpoint('early-settlement', paymentIdentifier)).reply(200, ProcessPaymentTypical);
        cpDataApiMock.onPost(getCancelPaymentEndpoint('early-settlement', paymentIdentifier)).reply(204, undefined);
        cpDataApiMock.onGet(getStartMatchmakerEndpoint(encryptedContract1Id)).reply(200, StartMatchmakerTypical);
        cpDataApiMock.onPost(getStartMatchmakerEndpoint(encryptedContract1Id)).reply(201, StartMatchmakerResponseTypical);
        cpDataApiMock.onGet(getRequestContactEndpoint()).reply(200, RequestContactTypical);
        cpDataApiMock.onPost(getRequestContactEndpoint()).reply(204, undefined);
        cpDataApiMock.onGet(getRequestAdditionalHelpEndpoint(encryptedContract1Id)).reply(200, RequestAdditionalHelpTypical);
        cpDataApiMock.onPost(getRequestBreathingSpaceEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onGet(getEarlySettlementEndpoint(encryptedContract1Id)).reply(200, EarlySettlementGenerate);
        cpDataApiMock.onPost(getEarlySettlementEndpoint(encryptedContract1Id)).reply(200, EarlySettlementExisting);
        cpDataApiMock.onGet(getEarlyTerminationEndpoint(encryptedContract1Id)).reply(200, EarlyTerminationGenerate);
        cpDataApiMock.onPost(getEarlyTerminationEndpoint(encryptedContract1Id)).reply(200, EarlyTerminationExisting);
        cpDataApiMock.onGet(getEndOfContractEndpoint(encryptedContract1Id)).reply(200, EndOfContractSolutionsWithoutSellOptionAndShowMatchmaker);
        cpDataApiMock.onPost(getRequestRefinanceQuoteEndpoint(encryptedContract1Id)).reply(204, undefined);
        cpDataApiMock.onPost(getRequestCallBackEndpoint(encryptedContract2Id)).reply(204, undefined);
        cpDataApiMock.onPost(getRequestAgreementExtensionEndpoint(encryptedContract2Id)).reply(204, undefined);
        cpDataApiMock.onGet(getDocumentCentreEndpoint()).reply(200, DocumentCentreWithMultipleContracts);
        cpDataApiMock.onGet(getDocumentListEndpoint(encryptedContract1Id)).reply(200, DocumentListMultiple);
        cpDataApiMock.onGet(getDocumentListEndpoint(encryptedContract2Id)).reply(200, DocumentListMultiple);
        cpDataApiMock.onGet(getFinancialSupportAndWellbeingEndpoint()).reply(200, FinancialSupportAndWellbeingWithOneFpsContract);
        cpDataApiMock.onGet(getInsuranceEndpoint(encryptedContract1Id)).reply(200, InsuranceResponseWithLetUsKnowOption);
    }
    cpContentApiMock.onAny().passThrough();
    cpDataApiMock.onAny().passThrough();
}

setContentEndpointBuildTimestamp(currentBuild.timestamp);
moment.tz.setDefault(UK_TIMEZONE);
render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
