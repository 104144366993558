export function getRequestAdditionalHelpEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-additional-help/${encryptedContractId}`;
}

export function getRequestBreathingSpaceEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-breathing-space/${encryptedContractId}`;
}

export function getRequestArrangementToPayEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-arrangement-to-pay/${encryptedContractId}`;
}

export function getRequestPromiseToPayEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-promise-to-pay/${encryptedContractId}`;
}
