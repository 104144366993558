import { Options, OptionsAvailableFormValues, OptionsAvailableSelections } from './types';

export const buildInitialValues = ({ option, consent }: OptionsAvailableSelections): OptionsAvailableFormValues => {
    return {
        option,
        consent,
    };
};

export const buildSelections = ({ option, consent }: OptionsAvailableFormValues): OptionsAvailableSelections => {
    return {
        option: option as Options,
        consent,
    };
};
