export enum Buttons {
    no = 'no',
    yes = 'yes',
}

export type FinancialCircumstances = {
    reducedIncome: boolean;
    unemployment: boolean;
    healthCondition: boolean;
    oversightOrMismanagementOfFinances: boolean;
    behindWithOtherCreditors: boolean;
    inAnIvaOrFilingForBankruptcy: boolean;
    other: boolean;
};

export type AdditionalInformationFormValues = {
    financialCircumstances: FinancialCircumstances;
    financialCircumstancesMoreDetail: string;
    additionalSupport: string;
    additionalSupportDetail: string;
    upToDateWithPriorityBills: string;
};

export type AdditionalInformationSelections = {
    financialCircumstances: FinancialCircumstances;
    financialCircumstancesMoreDetail: string;
    additionalSupport: boolean;
    additionalSupportDetail: string;
    upToDateWithPriorityBills: boolean;
};
