import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import {
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, FinanceBrandsEOC, ProductTypesEOC } from '@cp-uk/common';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath, startMatchmakerPagePath } from 'components/navigation/paths';
import { contractCategory, useFinanceCompanyDetails } from 'utils';
import { carOrVehicleText, retailerDescriptionText } from '../../helpers';

export type SolutionsExchangeViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    showMatchmaker: boolean;
    onBack: () => void;
};

export const SolutionsExchangeView: React.FC<SolutionsExchangeViewProps> = ({
    encryptedContractId,
    contractDescription,
    financeBrand,
    vehicleBrand,
    showMatchmaker,
    onBack,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation(['end-of-contract-solutions-exchange-view', 'end-of-contract']);
    const history = useHistory();

    const { onAction: onContactRetailerAction } = useAnalyticsActionTracker(
        'onEndOfContractPcpExchangeContactRetailer',
    );
    const { onAction: onRequestCallbackAction } = useAnalyticsActionTracker(
        'onEndOfContractPcpExchangeRequestCallback',
    );
    const { onAction: onFinanceOffersAction } = useAnalyticsActionTracker('onEndOfContractPcpExchangeFinanceOffers');
    const { onAction: onConfiguratorAction } = useAnalyticsActionTracker('onEndOfContractPcpExchangeConfigurator');
    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractPcpExchangeBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractPcpExchangeDashboard');
    const { onAction: onStartMatchmakerAction } = useAnalyticsActionTracker('onEndOfContractPcpStartMatchmaker');
    useAnalyticsPageViewTracker('endOfContractPcpExchange');

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);
    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);
    const callbackUrl = t(`callToActionUrls.${financeBrand}.callback`);
    const financeOffersUrl = t(`callToActionUrls.${financeBrand}.financeOffers`);
    const configuratorUrl = t(`callToActionUrls.${financeBrand}.configurator`);

    const onStartMatchmakerClick = (): void => {
        onStartMatchmakerAction();
        history.push(startMatchmakerPagePath(encryptedContractId));
    };
    const onContactRetailerClick = (): void => {
        onContactRetailerAction();
    };
    const onRequestCallbackClick = (): void => {
        onRequestCallbackAction();
    };
    const onFinanceOffersClick = (): void => {
        onFinanceOffersAction();
    };
    const onConfiguratorClick = (): void => {
        onConfiguratorAction();
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    const exchangeTitleAndIntroduction1Paragraphs: React.ReactNode = (
        <Fragment>
            <Paragraph testId={'exchangeTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.exchange.title', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'exchangeIntroductionParagraph1'}>
                {t(`paragraphs.exchange.introduction1.${showMatchmaker ? 'new' : 'old'}`, { vehicleBrand })}
            </Paragraph>
        </Fragment>
    );

    const exchangeIntroduction2ParagraphAndContactRetailerLink: React.ReactNode = (
        <Fragment>
            <Paragraph testId={'exchangeIntroductionParagraph2'}>{t('paragraphs.exchange.introduction2')}</Paragraph>
            <Paragraph>
                <Button
                    element={'a'}
                    testId={'contactRetailerLink'}
                    secondary
                    href={contactUrl}
                    onClick={onContactRetailerClick}
                    target={'_BLANK'}
                >
                    {t('buttons.contactRetailer', { shortCompanyName, retailerDescription })}
                </Button>
            </Paragraph>
        </Fragment>
    );

    return (
        <View testId={'solutionsExchangeView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            {showMatchmaker ? (
                <Fragment>
                    {exchangeTitleAndIntroduction1Paragraphs}
                    <Paragraph>
                        <Button type={'button'} testId={'startMatchmakerLink'} secondary onClick={onStartMatchmakerClick}>
                            {t('buttons.startMatchmaker')}
                        </Button>
                    </Paragraph>
                    {contactUrl && exchangeIntroduction2ParagraphAndContactRetailerLink}
                </Fragment>
            ) : (
                <Fragment>
                    {contactUrl && (
                        <Fragment>
                            {exchangeTitleAndIntroduction1Paragraphs}
                            {exchangeIntroduction2ParagraphAndContactRetailerLink}
                            {callbackUrl && (
                                <Paragraph>
                                    <Button
                                        element={'a'}
                                        testId={'requestCallbackLink'}
                                        secondary
                                        href={callbackUrl}
                                        onClick={onRequestCallbackClick}
                                        target={'_BLANK'}
                                    >
                                        {t('buttons.requestCallback')}
                                    </Button>
                                </Paragraph>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
            {financeOffersUrl && (
                <Fragment>
                    <Paragraph testId={'financeOffersTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.financeOffers.title')}
                    </Paragraph>
                    <Paragraph testId={'financeOffersIntroductionParagraph'}>
                        {t('paragraphs.financeOffers.introduction', { carOrVehicle })}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            element={'a'}
                            testId={'financeOffersLink'}
                            secondary
                            href={financeOffersUrl}
                            onClick={onFinanceOffersClick}
                            target={'_BLANK'}
                        >
                            {t('buttons.financeOffers')}
                        </Button>
                    </Paragraph>
                </Fragment>
            )}
            {configuratorUrl && (
                <Fragment>
                    <Paragraph testId={'configuratorTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.configurator.title')}
                    </Paragraph>
                    <Paragraph testId={'configuratorIntroductionParagraph'}>
                        {t('paragraphs.configurator.introduction', { shortCompanyName })}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            element={'a'}
                            testId={'configuratorLink'}
                            secondary
                            href={configuratorUrl}
                            onClick={onConfiguratorClick}
                            target={'_BLANK'}
                        >
                            {t('buttons.configurator', { shortCompanyName })}
                        </Button>
                    </Paragraph>
                </Fragment>
            )}
            <Notification
                className={'u-mv-small'}
                status={NotificationStatus.warning}
                text={t('notifications.externalWebsites.text')}
                testId={'externalWebsitesNotification'}
            />
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
