import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';
import { Button, ButtonContainer, Modal, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import {
    Notification,
    NotificationStatus,
    useAnalyticsPageViewTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    getRequestBreathingSpaceEndpoint,
    RequestBreathingSpace,
    Situation,
} from '@cp-uk/common';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath, financialSupportAndWellbeingTopicPagePath } from 'components/navigation/paths';
import { AlternativeNotification } from '../alternative-notification';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';
import { SimpleLink } from 'components/simple-link/SimpleLink';

type RequestAdditionalHelpBreathingSpaceProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    registrationNumber: string;
    totalArrears: number;
    situation: Situation;
    onBack: () => void;
};

export const BreathingSpaceView: React.FC<RequestAdditionalHelpBreathingSpaceProps> = ({
    encryptedContractId,
    contractDescription,
    registrationNumber,
    totalArrears,
    situation,
    onBack,
}) => {
    const { t } = useTranslation('request-additional-help-breathing-space-view');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const history = useHistory();
    const { onAction: onRequestAdditionalHelpSubmitSuccessAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitSuccess');
    const { onAction: onRequestAdditionalHelpSubmitFailedAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitFailed');
    const { onAction: onRequestAdditionalHelpBackAction } = useAnalyticsActionTracker('onRequestAdditionalHelpBack');
    useAnalyticsPageViewTracker('requestAdditionalHelpBreathingSpace');

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    const onRequestClick = (): Promise<void> => {
        const requestBreathingSpace: RequestBreathingSpace = {
            registrationNumber,
            totalArrears,
            situation,
        };
        setIsSubmitting(true);
        return CpDataApi.put<void>(getRequestBreathingSpaceEndpoint(encryptedContractId), requestBreathingSpace)
            .then(() => {
                setShowSuccessModal(true);
                setIsSubmitting(false);
                onRequestAdditionalHelpSubmitSuccessAction();
            })
            .catch(() => {
                setShowErrorModal(true);
                setIsSubmitting(false);
                onRequestAdditionalHelpSubmitFailedAction();
            });
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
    };
    const onBackClick = (): void => {
        onRequestAdditionalHelpBackAction();
        onBack();
    };

    const linkToFreeSupportAndIndependentAdvicePage = (
        <Link
            to={financialSupportAndWellbeingTopicPagePath('free-support-and-independent-advice')}
            data-testid={'freeSupportAndIndependentAdvicePageLink'}
        />
    );

    const linkToDealingWithTheFinancialImpactOfRisingCostsOfLiving = (
        <SimpleLink
            href={t('paragraphs.information.dealingWithTheFinancialImpactOfRisingCostsOfLivingUrl')}
            target={'_BLANK'}
            testId={'dealingWithTheFinancialImpactOfRisingCostsOfLivingLink'}
        />
    );

    return (
        <View testId={'breathingSpaceView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph>
                {textWithComponents(t, 'paragraphs.information.text', {
                    linkToFreeSupportAndIndependentAdvicePage,
                    linkToDealingWithTheFinancialImpactOfRisingCostsOfLiving,
                })}
            </Paragraph>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.warning}
                headline={t('notifications.responsibility.headline')}
                text={t('notifications.responsibility.text')}
            />
            <ButtonContainer nav className={'u-mb'}>
                <Button type={'button'} testId={'backButton'} disabled={isSubmitting} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'requestButton'} disabled={isSubmitting} onClick={onRequestClick}>
                    {t('translation:editableSectionNav.request')}
                </Button>
            </ButtonContainer>
            <AlternativeNotification />
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.dashboard')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'successModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'errorModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
        </View>
    );
};
