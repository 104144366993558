import React, { Suspense, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker as useOldAnalyticsActionTracker,
    useAnalyticsPageViewTracker as useOldAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import {
    getStartMatchmakerEndpoint,
    StartMatchmakerBO,
    StartMatchmakerRequest,
    StartMatchmakerResponse,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { currentBrandName, currentBronson } from 'config';
import {
    openInNewWindow,
    useAnalyticsActionTracker as useNewAnalyticsActionTracker,
    useAnalyticsPageViewTracker as useNewAnalyticsPageViewTracker,
} from 'utils';
import { ValidatedSelect, ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { MatchmakerInstructionStepper } from 'components/matchmaker-instruction-stepper/MatchmakerInstructionStepper';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { fetchStartMatchmaker } from './StartMatchmakerSlice';
import { selectStartMatchmakerForSpecific } from './StartMatchmakerSelector';
import { validationSchema } from './StartMatchmakerValidation';
import { StartMatchmakerFormValues } from './types';

export const StartMatchmakerUi: React.FC<{ startMatchmaker: StartMatchmakerBO | undefined }> = ({
    startMatchmaker,
}) => {
    const [newTrackingId, setNewTrackingId] = useState<string>('startMatchmaker');
    const { t } = useTranslation('start-matchmaker');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const history = useHistory();

    const { onAction: onOldLaunchAction } = useOldAnalyticsActionTracker('onContinueToMatchmaker');
    useOldAnalyticsPageViewTracker('startMatchmaker', !!startMatchmaker);

    const { onAction: onNewContinueAction } = useNewAnalyticsActionTracker('startMatchmakerForward');
    const { onAction: onNewReturnAction } = useNewAnalyticsActionTracker('startMatchmakerReturn');
    const { onAction: onNewLaunchAction } = useNewAnalyticsActionTracker('startMatchmakerLaunch');
    useNewAnalyticsPageViewTracker(newTrackingId, !!startMatchmaker, startMatchmaker?.financeBrand);

    if (startMatchmaker === undefined) {
        return null;
    }

    const newWindowContents = t('newWindow.contents', {
        brandName: currentBrandName,
        bronsonBaseUrl: currentBronson.baseUrl,
        bronsonTheme: currentBronson.theme,
        bronsonVersion: currentBronson.version,
        bronsonFaviconPath: currentBronson.faviconPath || '',
        logoClassFragment: !!currentBronson.modifier ? ` c-logo--${currentBronson.modifier}` : '',
        logoFileFragment: !!currentBronson.modifier ? `-${currentBronson.modifier}` : '',
        logoExtFragment: currentBronson.logoExt || 'svg',
    });

    const {
        encryptedContractId,
        financeBrand,
        title,
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
        contractDescription,
        address,
        equityAmount,
        valuationAverage,
        settlementEstimate,
        dealerCode,
        dealerName,
        isConsideredCompleted,
        creationDateTime,
        productType,
    } = startMatchmaker;

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const initialValues: StartMatchmakerFormValues = {
        phoneNumber: '',
    };

    const phoneNumberLabel = (key: string, value: string): string => {
        return `${t(`phoneNumbers.${key}`)} - ${value}`;
    };

    const phoneNumberSelectItems = (): ValidatedSelectItem[] => {
        const phoneNumbers = {
            mobileNumber,
            homePhoneNumber,
            workPhoneNumber,
        };
        return Object.entries(phoneNumbers)
            .filter(([, phoneNumberValue]) => !!phoneNumberValue)
            .map(
                ([phoneNumberName, phoneNumberValue]): ValidatedSelectItem => {
                    return {
                        label: phoneNumberLabel(phoneNumberName, phoneNumberValue ?? ''),
                        value: phoneNumberValue ?? '',
                    };
                },
            );
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
        setNewTrackingId('startMatchmaker');
    };

    const buildStartMatchmakerRequest = ({ phoneNumber }: StartMatchmakerFormValues): StartMatchmakerRequest => {
        return {
            productType,
            financeBrand,
            title,
            firstName,
            lastName,
            emailAddress: emailAddress ?? '',
            phoneNumber,
            address,
            equityAmount,
            valuationAverage,
            settlementEstimate,
            dealerCode,
            dealerName,
        };
    };

    const onReturnClick = (): void => {
        history.push(dashboardPagePath());
        onNewReturnAction('returnButton', financeBrand);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <ContactDetailsNotification
                className={'u-mb'}
                mode={contactDetailsNotificationMode}
                emailAddress={emailAddress}
            />
            <section className={'u-mb'}>
                <MatchmakerInstructionStepper
                    productType={productType}
                    financeBrand={financeBrand}
                    isConsideredCompleted={isConsideredCompleted}
                    step={isConsideredCompleted ? 4 : 2}
                />
                {isConsideredCompleted && (
                    <Notification
                        className={'u-mt'}
                        status={NotificationStatus.info}
                        headline={t('notifications.consideredCompleted.headline')}
                        text={t('notifications.consideredCompleted.text', { creationDateTime })}
                        testId={'consideredCompletedNotification'}
                    />
                )}
            </section>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values: StartMatchmakerFormValues): Promise<void> => {
                    const startMatchmakerRequest = buildStartMatchmakerRequest(values);
                    onNewContinueAction('continueButton', financeBrand);
                    setIsSubmitting(true);
                    const newWindow = openInNewWindow('about:blank');
                    newWindow?.document?.write(newWindowContents);
                    return CpDataApi.post<StartMatchmakerResponse>(
                        getStartMatchmakerEndpoint(encryptedContractId),
                        startMatchmakerRequest,
                    )
                        .then(({ data }) => {
                            setIsSubmitting(false);
                            history.push(dashboardPagePath());
                            newWindow?.location?.replace(data.redirectUrl);
                            onOldLaunchAction();
                            onNewLaunchAction('launchWebsite', data.redirectUrl);
                        })
                        .catch(() => {
                            setIsSubmitting(false);
                            newWindow?.close();
                            setShowErrorModal(true);
                            setNewTrackingId('startMatchmakerError');
                        });
                }}
            >
                {(formik: FormikProps<StartMatchmakerFormValues>): React.ReactNode => (
                    <Form className={'uk-start-matchmaker-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-phone-number'}
                                    label={t('phoneNumber.label')}
                                    placeholder={t('phoneNumber.placeholder')}
                                    selectItems={phoneNumberSelectItems()}
                                    name={'phoneNumber'}
                                    testId={'phoneNumber'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        testId={'returnButton'}
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={onReturnClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button
                                        testId={'continueButton'}
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showErrorModal}
                className={'modal-error'}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </Suspense>
    );
};

const StartMatchmakerWithHandlers = withLoadingAndNoConnectionHandler(StartMatchmakerUi);

export const StartMatchmaker: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: startMatchmaker, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchStartMatchmaker,
        selectStartMatchmakerForSpecific,
        encryptedContractId,
    );

    return (
        <StartMatchmakerWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            startMatchmaker={startMatchmaker}
        />
    );
};
