import { AdditionalInformationFormValues, AdditionalInformationSelections, Buttons } from './types';

const booleanToButtons = (value: boolean): Buttons => {
    return value ? Buttons.yes : Buttons.no;
};

const buttonsToBoolean = (value: string): boolean => {
    return value === Buttons.yes;
};

export const buildInitialValues = (
    selections: AdditionalInformationSelections | undefined,
): AdditionalInformationFormValues => {
    if (selections === undefined) {
        return {
            financialCircumstances: {
                reducedIncome: false,
                unemployment: false,
                healthCondition: false,
                oversightOrMismanagementOfFinances: false,
                behindWithOtherCreditors: false,
                inAnIvaOrFilingForBankruptcy: false,
                other: false,
            },
            financialCircumstancesMoreDetail: '',
            additionalSupport: '',
            additionalSupportDetail: '',
            upToDateWithPriorityBills: '',
        };
    }

    const {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesMoreDetail,
        additionalSupport,
        additionalSupportDetail,
        upToDateWithPriorityBills,
    } = selections;
    return {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesMoreDetail,
        additionalSupport: booleanToButtons(additionalSupport),
        additionalSupportDetail,
        upToDateWithPriorityBills: booleanToButtons(upToDateWithPriorityBills),
    };
};

export const buildSelections = ({
    financialCircumstances: {
        reducedIncome,
        unemployment,
        healthCondition,
        oversightOrMismanagementOfFinances,
        behindWithOtherCreditors,
        inAnIvaOrFilingForBankruptcy,
        other,
    },
    financialCircumstancesMoreDetail,
    additionalSupport,
    additionalSupportDetail,
    upToDateWithPriorityBills,
}: AdditionalInformationFormValues): AdditionalInformationSelections => {
    return {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesMoreDetail,
        additionalSupport: buttonsToBoolean(additionalSupport),
        additionalSupportDetail,
        upToDateWithPriorityBills: buttonsToBoolean(upToDateWithPriorityBills),
    };
};

export const financialCircumstancesMoreDetailIsMandatory = (financialCircumstancesOther: boolean): boolean => {
    return financialCircumstancesOther;
};

export const additionalSupportDetailIsMandatory = (additionalSupport: Buttons): boolean => {
    return additionalSupport === Buttons.yes;
};

export const additionalSupportDetailDisabled = (additionalSupport: Buttons): boolean => {
    return additionalSupport !== Buttons.yes;
};
