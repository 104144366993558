import { TFunction } from 'i18next';
import { SimpleLink } from '../components/simple-link/SimpleLink';
import React from 'react';

export const getTelHref = (phoneNumber: string): string => {
    return `tel:${phoneNumber.replace(/<nbsp\/>/g, '')}`;
};

export const buildLinkToPhoneNumber = (tOrPhoneNumber: TFunction | string, key: string): JSX.Element => {
    if (typeof tOrPhoneNumber === 'function') {
        const t = tOrPhoneNumber as TFunction;
        const phoneNumber = t(`translation:phoneNumbers.${key}`);
        return buildLinkToPhoneNumber(phoneNumber, key);
    }

    const phoneNumber = tOrPhoneNumber as string;
    return <SimpleLink href={getTelHref(phoneNumber)} testId={`${key}PhoneNumberLink`} />;
};
