import { TFunction } from 'i18next';
import { AvailableDaysFormValues, AvailableTimes } from './types';

export const formatTimeForEmail = (time: string): string => {
    return time.replace(':00', '');
};

export const buildCallbackAvailabilityString = (
    t: TFunction,
    earliestTime: AvailableTimes,
    latestTime: AvailableTimes,
    availableDays: AvailableDaysFormValues,
) => {
    const days = Object.entries(availableDays)
        .filter(([, value]) => !!value)
        .map(([key]) => t(`availableDays.${key}.label`))
        .join(', ');

    const displayDays = days === '' ? t('availableDays.anyDay') : days;

    const earliestPreference = earliestTime !== AvailableTimes.noPreferredEarliest;
    const latestPreference = latestTime !== AvailableTimes.noPreferredLatest;

    const displayEarliestTime = earliestPreference ? formatTimeForEmail(t(`availableTimes.${earliestTime}`)) : '';
    const displayLatestTime = latestPreference ? formatTimeForEmail(t(`availableTimes.${latestTime}`)) : '';

    if (earliestPreference && latestPreference) {
        return `${displayDays}, ${t('availableTimes.between')} ${displayEarliestTime} ${t('availableTimes.and')} ${displayLatestTime}`;
    } else if (earliestPreference) {
        return `${displayDays}, ${t('availableTimes.after')} ${displayEarliestTime}`;
    } else if (latestPreference) {
        return `${displayDays}, ${t('availableTimes.before')} ${displayLatestTime}`;
    } else {
        return displayDays;
    }
};

export const refinanceMonthsToNumber = (refinanceMonths: string): number => {
    return Number(refinanceMonths.replace('M', ''));
};
