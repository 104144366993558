import React, { Fragment } from 'react';
import { ContractDescriptionBO, formatAsDate, formatAsCurrency, CompleteViewTerminationBO } from '@cp-uk/common';
import { useHistory } from 'react-router-dom';
import {
    InfoIcon,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    SectionHeading,
    Paragraph,
    ButtonContainer,
    Button,
    Card,
    Layout,
    DescriptionList,
} from '@vwfs-bronson/bronson-react';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { dashboardPagePath } from 'components/navigation/paths';
import { View } from 'components/view/View';
import {
    buildLinkToPhoneNumber,
    textAsHtml,
    textWithComponents,
    useBankDetails,
    useFinanceCompanyDetails
} from 'utils';
import { useTranslation } from 'react-i18next';
import { analyticsDiscriminator } from '../constants';
import { carOrVehicleText } from '../../helpers';

export const CompleteView: React.FC<{
    contractDescription: ContractDescriptionBO;
    completeView: CompleteViewTerminationBO | undefined;
    onReturn: () => void;
}> = ({ contractDescription, completeView, onReturn }) => {
    const { t } = useTranslation('early-termination-complete-view');
    const history = useHistory();
    const { phoneNumber } = useFinanceCompanyDetails(
        completeView?.productType,
        completeView?.financeBrand,
    );
    const { bank, accountName, accountNumber, sortCode } = useBankDetails();
    const titleWithTooltip = (title: string, tooltip: string): React.ReactNode => {
        return (
            <Fragment>
                {title}
                <InfoIcon text={tooltip} />
            </Fragment>
        );
    };
    const { onAction: onEarlyTerminationCompletePrintAction } = useAnalyticsActionTracker(
        'onEarlySettlementCompletePrint',
    );
    useAnalyticsPageViewTracker('earlySettlementComplete', !!completeView, analyticsDiscriminator);

    if (completeView === undefined) {
        return null;
    }

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const { financeBrand, contractNumber, terminationAmount, quoteExpiryDate } = completeView;
    const carOrVehicle = carOrVehicleText(financeBrand);

    const onReturnClick = (): void => {
        onReturn();
    };

    const onPrintClick: () => void = () => {
        onEarlyTerminationCompletePrintAction(analyticsDiscriminator);
        window.print();
    };

    return (
        <View testId={'completeView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-mb'} testId={'informationParagraph1'}>
                {textWithComponents(t, 'paragraphs.information1', { linkToPhoneNumber }, { phoneNumber })}
            </Paragraph>
            <Card element="article" className={`u-mb u-text-left`} title={t('bankDetails.title')}>
                <Layout equalHeight divider>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <DescriptionList testId={'bankDetails'} horizontal>
                            <DescriptionTermAndDetail
                                testId={'bank'}
                                term={t('bankDetails.bank.title')}
                                detail={bank}
                            />
                            <DescriptionTermAndDetail
                                testId={'accountName'}
                                term={t('bankDetails.accountName.title')}
                                detail={accountName}
                            />
                            <DescriptionTermAndDetail
                                testId={'sortCode'}
                                term={t('bankDetails.sortCode.title')}
                                detail={sortCode}
                            />
                            <DescriptionTermAndDetail
                                testId={'accountNumber'}
                                term={t('bankDetails.accountNumber.title')}
                                detail={accountNumber}
                            />
                            <DescriptionTermAndDetail
                                testId={'referenceNumber'}
                                term={titleWithTooltip(
                                    t('bankDetails.referenceNumber.title'),
                                    t('bankDetails.referenceNumber.tooltip'),
                                )}
                                detail={contractNumber}
                            />
                        </DescriptionList>
                    </Layout.Item>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <span>
                            <DescriptionList testId={'terminationDetails'} horizontal>
                                <DescriptionTermAndDetail
                                    testId={'terminationAmount'}
                                    term={t('terminationDetails.terminationAmount.title')}
                                    detail={formatAsCurrency(terminationAmount)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'quoteExpiryDate'}
                                    term={titleWithTooltip(
                                        t('terminationDetails.quoteExpiryDate.title'),
                                        t('terminationDetails.quoteExpiryDate.tooltip'),
                                    )}
                                    detail={formatAsDate(quoteExpiryDate)}
                                />
                            </DescriptionList>
                        </span>
                    </Layout.Item>
                </Layout>
            </Card>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                text={t('notifications.information.text')}
                testId={'informationNotification'}
            />
            <Paragraph className={'u-mb'} testId={'informationParagraph2'}>
                {textAsHtml(t('paragraphs.information2', { carOrVehicle }))}
            </Paragraph>
            <Paragraph className={'u-mb'} testId={'informationParagraph3'}>
                {textAsHtml(t('paragraphs.information3'))}
            </Paragraph>
            <ButtonContainer nav>
                <Button
                    type={'button'}
                    testId={'printButton'}
                    icon={'semantic-print'}
                    iconReversed
                    simple
                    small
                    link
                    onClick={onPrintClick}
                >
                    {t('translation:editableSectionNav.print')}
                </Button>
                <Button type={'button'} testId={'returnButton'} onClick={onReturnClick} secondary>
                    {t('translation:editableSectionNav.return')}
                </Button>
                <Button
                    type={'button'}
                    testId={'dashboardButton'}
                    onClick={(): void => history.push(dashboardPagePath())}
                >
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
